<template>
  <div>
    <SchemaFields
      v-if="
        field.type == 'conditional' &&
        currentValue &&
        field.value.includes(valueToCheck)
      "
      :schema="[field.item]"
      :updateParent="update"
      :value="value"
    />
  </div>
</template>
<script>
export default {
  name: "SchemaFieldConditional",
  props: {
    value: Object,
    field: Object,
    currentValue: Object,
    update: Function,
  },
  components: {
    SchemaFields: () => import("./SchemaFields"),
  },
  computed: {
    valueToCheck() {
      if (this.field.check.includes(".")) {
        // loop through dot notation
        const valueToCheck = this.field.check
          .split(".")
          .reduce((previousValue, currentValue) => {
            // check if array item being reference
            if (currentValue.includes("]")) {
              const indexString = currentValue
                .replace("]", "")
                .substring(currentValue.length - 2);
              const attribute = currentValue.substring(
                0,
                currentValue.length - 3
              );
              const index = parseInt(indexString);

              if (previousValue && previousValue[attribute])
                return previousValue[attribute][index];
              return null;
            }

            if (previousValue && previousValue[currentValue])
              return previousValue[currentValue];
            return null;
          }, this.currentValue);

        return valueToCheck;
      }

      return this.currentValue[this.field.check];
    },
  },
};
</script>