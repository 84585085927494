<template>
  <div id="users-page" class="p-4">
    <v-card>
      <div class="p-4">
        <div><span class="text-2xl text-dark font-bold">Users</span></div>
        <div class="flex mt-4">
          <v-text-field
            class="p-0 m-0"
            v-model="searchTerm"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer />
          <div class="flex flex-row-reverse">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" dark v-bind="attrs" class="bg-green-light">
                  create new
                  <v-divider vertical class="ml-4 mr-3 border-white" />
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list class="p-0">
                <v-list-item class="p-0">
                  <v-btn
                    text
                    @click="showCreateNewSingle = true"
                    class="p-0 w-full"
                  >
                    Single user
                  </v-btn>
                </v-list-item>
                <v-list-item class="p-0">
                  <v-btn
                    text
                    @click="showCreateNewBulk = true"
                    class="p-0 w-full"
                  >
                    Bulk import
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>

            <ModelEdit
              v-model="showCreateNewSingle"
              type="User"
              title="Create a new user"
              v-on:saved="() => getUsers()"
              v-on:alert="(alert) => $emit('alert', alert)"
            />

            <!-- TODO do we need to implement bulk update feature? -->
            <!-- <v-btn disabled v-if="selectedUsers.length" color="blue" class="mr-2">
                update
              </v-btn> -->
            <v-btn
              v-if="selectedUsers.length"
              dark
              color="red"
              class="mr-2"
              @click="endEmploymentForUsers"
            >
              End employment{{ selectedUsers.length > 1 ? "s" : null }}
            </v-btn>
          </div>
        </div>
      </div>

      <v-data-table
        v-model="selectedUsers"
        :headers="headers"
        :items="users"
        :items-per-page="itemsPerPage"
        :server-items-length="totalCount"
        :footer-props="footerOptions"
        :loading="loading"
        :disable-sort="true"
        show-select
        @update:page="onUserPageUpdate"
        @click:row="onRowClick"
        item-key="_id"
        no-data-text="No users found"
      >
        <template v-slot:[`item.fullName`]="{ item }">
          <v-avatar size="36px" color="teal">
            <v-img
              v-if="item.profileImageUrl"
              alt="Avatar"
              :src="item.profileImageUrl"
            />
            <span v-else class="white--text">{{ item.initials }}</span>
          </v-avatar>
          <span class="ml-2">{{ item.fullName }}</span>
        </template>
        <template v-slot:[`item.squads`]="{ item }">
          <span>{{ getSquadsList(item) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { get, mutate, handleError } from "../../services/database";

import ModelEdit from "../../components/ModelEdit.vue";

export default {
  name: "UsersPage",
  components: {
    ModelEdit,
  },
  data: () => ({
    showCreateNewSingle: false,
    showCreateNewBulk: false,
    selectedUsers: [],
    users: [],
    itemsPerPage: 10,
    searchTerm: "",
    searchTimeout: null,
    loading: false,
    currentPage: 0,
    totalCount: 0,
    footerOptions: {
      "items-per-page-options": [10],
    },
    headers: [
      {
        text: "Name",
        value: "fullName",
      },
      {
        text: "Job Title",
        value: "jobTitle",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Squad(s)",
        value: "squads",
      },
    ],
  }),
  mounted() {
    this.getUsers();
  },
  watch: {
    searchTerm(newValue) {
      this.loading = true;
      this.selectedUsers = [];
      if (!newValue.length) {
        this.getUsers(0);
      } else {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.getUsers(0), 1000);
      }
    },
  },
  methods: {
    onUserPageUpdate(page) {
      const dbPage = page - 1;

      this.currentPage = dbPage;
      this.getUsers(dbPage);
    },
    // TODO getUsers seems to be called twice
    getUsers(page = 0) {
      this.loading = true;
      return get("Users", "overview", null, {
        itemsPerPage: this.itemsPerPage,
        page,
        searchTerm: this.searchTerm,
      })
        .then(({ data, totalCount }) => {
          this.users = data;
          this.totalCount = totalCount;
        })
        .catch((error) => {
          handleError(error, this);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSquadsList(user) {
      return user.squads.map((squad) => squad.name).join(", ");
    },

    onRowClick(item) {
      this.$router.push(`/users/${item._id}`);
    },
    endEmploymentForUsers() {
      const userIds = this.selectedUsers.map((user) => user._id);
      // TODO allow users to select a date for termination
      return mutate("endEmployment", userIds)
        .then(() => {
          return this.getUsers(this.currentPage);
        })
        .catch((error) => {
          handleError(error, this);
        });
    },
  },
};
</script>
<style>
#users-page tbody tr {
  cursor: pointer;
}
</style>