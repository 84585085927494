<template>
  <div
    class="multiple-inputs"
    :class="{
      flex: field.display == 'flex',
    }"
    :style="field.style"
  >
    <SchemaFields
      v-for="(fieldItem, index) in field.items"
      :key="index"
      :schema="[fieldItem]"
      :style="{ flex: fieldItem.flex }"
      :class="{ 'ml-2': index >= 1 && field.display == 'flex' }"
      :updateParent="update"
      :value="value"
    />
  </div>
</template>
<script>
export default {
  name: "SchemaFieldMultiple",
  props: {
    value: Object,
    field: Object,
    update: Function,
  },
  components: {
    SchemaFields: () => import("./SchemaFields"),
  },
};
</script>
<style>
.multiple-inputs .v-input__control,
.multiple-inputs .v-input__control > * {
  height: 100%;
}
</style>