<template>
  <!-- TODO show work limit card vs project work hours estimates -->
  <!-- TODO dropdown to select today, week, month, quarter, year -->
  <div id="dashboard-page" class="flex flex-column h-full p-4">
    <div class="flex flex-initial mb-8">
      <v-card
        class="flex-1 mr-8 text-dark bg-green-light border-none"
        elevation="20"
      >
        <v-skeleton-loader
          type="dashboard-highlight"
          :types="skeletonLoaderCustomTypes"
          :loading="loading"
        >
          <slot>
            <div class="p-4">
              <div
                class="
                  text-light text-xs
                  my-2
                  font-medium
                  uppercase
                  tracking-widest
                "
              >
                Capacity remaining today
              </div>
              <div class="text-3xl text-light font-bold mb-4">
                {{ companyCapacity.remaining.day }}
                <span class="text-base"
                  >/ {{ companyCapacity.total.day }} hours
                </span>
              </div>
              <v-divider dark />
              <div class="mt-4 text-light">
                Previous day: {{ companyCapacity.previous.day }} hours
              </div>
            </div>
          </slot>
        </v-skeleton-loader>
      </v-card>

      <v-card class="flex-1 mr-8 text-dark" elevation="15">
        <v-skeleton-loader
          type="dashboard-highlight"
          :types="skeletonLoaderCustomTypes"
          :loading="loading"
        >
          <slot>
            <div class="p-4">
              <div class="text-xs my-2 font-medium uppercase tracking-widest">
                Weekly capacity remaining
              </div>
              <div class="text-dark text-3xl font-bold mb-4">
                {{ companyCapacity.remaining.week }}
                <span class="text-base"
                  >/ {{ companyCapacity.total.week }} hours
                </span>
              </div>
              <v-divider />
              <div class="mt-4">
                Previous week: {{ companyCapacity.previous.week }} hours
              </div>
            </div>
          </slot>
        </v-skeleton-loader>
      </v-card>

      <v-card class="flex-1 mr-8" elevation="15">
        <v-skeleton-loader
          type="dashboard-highlight"
          :types="skeletonLoaderCustomTypes"
          :loading="loading"
        >
          <slot>
            <div class="p-4">
              <div class="text-xs my-2 font-medium uppercase tracking-widest">
                Monthly capacity remaining
              </div>
              <div class="text-dark text-3xl font-bold mb-4">
                {{ companyCapacity.remaining.month }}
                <span class="text-base"
                  >/ {{ companyCapacity.total.month }} hours
                </span>
              </div>
              <v-divider />
              <div class="mt-4">
                Previous month: {{ companyCapacity.previous.month }} hours
              </div>
            </div>
          </slot>
        </v-skeleton-loader>
      </v-card>

      <v-card class="flex-1" elevation="15">
        <v-skeleton-loader
          type="dashboard-highlight"
          :types="skeletonLoaderCustomTypes"
          :loading="loading"
        >
          <slot>
            <div class="p-4">
              <div class="text-xs my-2 font-medium uppercase tracking-widest">
                Quarterly capacity remaining
              </div>
              <div class="text-dark text-3xl font-bold mb-4">
                {{ companyCapacity.remaining.quarter }}
                <span class="text-base"
                  >/ {{ companyCapacity.total.quarter }} hours
                </span>
              </div>
              <v-divider />
              <div class="mt-4">
                Previous quarter: {{ companyCapacity.previous.quarter }} hours
              </div>
            </div>
          </slot>
        </v-skeleton-loader>
      </v-card>
    </div>
    <div class="flex-1">
      <div class="flex h-full">
        <!-- <v-card class="flex-1 p-4 mr-8"> -->
        <v-card class="flex-1 p-4">
          <Chart
            type="line"
            :labels="totalCapacityChartLabels"
            :datasets="totalCapacityChartDatasets"
            :options="chartOptions"
          />
        </v-card>
        <!-- <v-card class="flex-1 p-4">
          <Chart
            type="line"
            :labels="costChartLabels"
            :datasets="costChartDatasets"
            :options="chartOptions"
          />
        </v-card> -->
      </div>
    </div>
  </div>
</template>
<script>
import format from "date-fns/format";
import { merge } from "lodash";

import { get, handleError } from "../../services/database";
import Chart from "../../components/Chart.vue";

export default {
  name: "DashboardPage",
  props: {
    user: Object,
  },
  components: {
    Chart,
  },
  data: () => ({
    loading: false,
    company: null,
    reports: null,
    skeletonLoaderCustomTypes: {
      "dashboard-highlight": "text, heading, divider, text",
    },
    chartOptions: {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        // y: {
        //   grace: "10%",
        // },
      },
      plugins: {
        legend: {
          onClick: (e) => e.stopPropagation(),
        },
      },
    },
  }),
  computed: {
    companyCapacity() {
      let companyCapacity = {
        total: { day: 0, week: 0, month: 0, quarter: 0 },
        remaining: { day: 0, week: 0, month: 0, quarter: 0 },
        previous: { day: 0, week: 0, month: 0, quarter: 0 },
      };

      if (this.company) {
        companyCapacity = merge(companyCapacity, this.company.capacity);
      }

      return companyCapacity;
    },

    totalCapacityChartLabels() {
      if (this.reports) {
        const labels = this.reports.totalCapacity.map((report) =>
          format(new Date(report.dateMilliseconds), "MMM")
        );

        return labels;
      }
      return [];
    },
    totalCapacityChartDatasets() {
      let dataTotal = [];
      // let dataActual = [];

      if (this.reports) {
        dataTotal = this.reports.totalCapacity.map((report) => report.value);
        // dataActual = this.reports.totalCapacity.map((report) => report.value);
      }

      return [
        {
          label: "Total Capacity",
          data: dataTotal,
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.3,
        },
        // {
        //   label: "Actual Capacity",
        //   data: dataActual,
        //   fill: false,
        //   borderColor: "rgb(72,61,139)",
        //   tension: 0.3,
        // },
      ];
    },

    // costChartLabels() {
    //   if (this.reports) {
    //     const labels = this.reports.cost.map((report) =>
    //       format(new Date(report.dateMilliseconds), "MMM")
    //     );

    //     return labels;
    //   }

    //   return [];
    // },
    // costChartDatasets() {
    //   let data = [];

    //   if (this.reports) {
    //     data = this.reports.cost.map((report) => report.value);
    //   }

    //   return [
    //     {
    //       label: "Total Capacity Cost",
    //       data,
    //       fill: false,
    //       borderColor: "rgb(75, 192, 192)",
    //       tension: 0.3,
    //     },
    //   ];
    // },
  },
  mounted() {
    // TODO try using skeletons
    // this.$emit("loading", true);

    if (this.user.currentEmployers) {
      this.loading = true;
      const promises = [
        // TODO assumption for now that you can only be employed to one place
        get("Company", "overview", this.user.currentEmployers[0]._id),
        get("CompanyReport", "full", this.user.currentEmployers[0]._id, {
          type: "monthly",
          count: 6,
        }),
      ];

      Promise.all(promises)
        .then(([company, reports]) => {
          this.company = company;
          this.reports = reports;
        })
        .catch((error) => {
          handleError(error, this);
        })
        .finally(() => {
          // TODO try skeleton loading
          // this.$emit("loading", false);
          this.loading = false;
        });
    }
  },
};
</script>
<style>
#dashboard-page .v-skeleton-loader__dashboard-highlight {
  padding: 1rem;
}

#dashboard-page .theme--dark.v-divider {
  border-color: rgba(255, 255, 255, 0.4);
}

#dashboard-page .v-skeleton-loader__text {
  margin-top: 8px;
}

#dashboard-page .v-skeleton-loader__text:last-of-type {
  margin-top: 20px;
}

#dashboard-page .v-skeleton-loader__heading {
  margin-top: 20px;
}

#dashboard-page .v-skeleton-loader__divider {
  margin-top: 20px;
}
</style>
