<template>
  <v-dialog v-model="isModalOpen" :max-width="variant == 2 ? 700 : 350">
    <v-card>
      <div :class="{ 'flex': variant == 2 }">
        <div class="text-center" :class="{ 'flex flex-1': variant == 2 }">
          <!-- <div class="mt-10 mb-2 text-center w-full"> -->
            <div class="text-center w-full" :class="{ 'mt-8': variant == 1, 'm-auto': variant == 2 }">
            <img
              src="@/assets/images/logo_full.svg"
              class="m-auto w-64"
              alt="trackk logo"
            />
            <div class="text-2xl font-bold mt-4">Sign up for an account</div>
          </div>
        </div>
        <!-- <v-card-text> Some content here </v-card-text> -->
        <div class="p-4" :class="{ 'flex-1': variant == 2 }">
          <AuthWithEmailForm>
            <template v-slot:button-text>Sign up with email</template>
            <template v-slot:help-text
              >An email will be sent to the above address with a magic link that
              will sign you up</template
            >
          </AuthWithEmailForm>
          <AuthWithGoogleButton class="mt-4"
            >Sign up with Google</AuthWithGoogleButton
          >
          <AuthWithMicrosoftButton class="mt-4"
            >Sign up with Microsoft</AuthWithMicrosoftButton
          >
        </div>
      </div>

      <div class="my-4 text-center">
        <span>Already have an account?</span>
        <v-btn
          id="sign-up-btn"
          plain
          text
          color="primary"
          class="p-0 ml-1"
          :ripple="false"
          @click="isModalOpen = false"
        >
          <span>Sign in</span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import AuthWithEmailForm from "./AuthWithEmailForm.vue";
import AuthWithGoogleButton from "./AuthWithGoogleButton.vue";
import AuthWithMicrosoftButton from "./AuthWithMicrosoftButton.vue";

export default {
  name: "SignUpModal",
  props: {
    value: Boolean,
  },
  components: {
    AuthWithEmailForm,
    AuthWithGoogleButton,
    AuthWithMicrosoftButton,
  },
  computed: {
    isModalOpen: {
      get() {
        return this.value || false;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  data() {
    return {
      variant: 1,
    }
  }
};
</script>