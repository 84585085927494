<template>
  <div class="h-full flex">
    <v-navigation-drawer permanent>
      <!-- <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title"> Application </v-list-item-title>
          <v-list-item-subtitle> subtext </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider> -->

      <v-list dense nav>
        <v-list-item
          v-for="item in navItems"
          :key="item.title"
          :to="item.to"
          link
        >
          <!-- <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="h-full bg-light pl-0">
      <router-view
        v-on:alert="(alert) => $emit('alert', alert)"
        v-on:loading="(isLoading) => (loading = isLoading)"
        :user="user"
      />
    </v-main>
  </div>
</template>
<script>
import { signOut } from "../services/api";

export default {
  name: "AccountPage",
  props: {
    user: Object,
  },
  data: function () {
    const navItems = [
      { title: "Profile", to: `/accounts/${this.user._id}/profile` },
    ];

    // if (
    //   this.user &&
    //   this.user.permissions &&
    //   this.user.permissions.includes("manage_company")
    // ) {
    //   navItems.push({
    //     title: "Company",
    //     to: `/accounts/${this.user._id}/company`,
    //   });
    // }

    if (
      this.user &&
      this.user.employments &&
      this.user.employments[0].login.permissions.includes("manage_users")
    ) {
      navItems.push({
        title: "User management",
        to: `/accounts/${this.user._id}/users`,
      });
    }

    return {
      navItems,
    };
  },
  methods: {
    signOut() {
      signOut().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>