import { checkUser as checkUserAPI } from './api'

export function checkUser() {
  return checkUserAPI().then((response) => {
    if (response.data.user) {
      return response.data.user;
    } else {
      return false;
    }
  }).catch((error) => {
    console.error(error)
    return false
  })
}

export function isAuthenticated() {
  return checkUserAPI().then((response) => {
    if (response.data.user && response.data.user.employments[0].login.allowed && response.data.user.employments[0].login.signUpCompleted) {
      return true;
    } else {
      return false;
    }
  }).catch((error) => {
    console.error(error)
    return false
  })
}

export function parseTimeShiftDays(days) {
  const valueForDays = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sund: 6,
  };

  let display = "";
  let chain = 0;
  let chainStart = 0;
  for (let i = 0; i < days.length; i++) {
    const currentDay = days[i];
    const previousDay = days[i - 1];
    if (
      (i === 0 && days.length > 1) ||
      (i < days.length - 1 &&
        valueForDays[previousDay] + 1 === valueForDays[currentDay])
    ) {
      chain++;
    } else {
      if (chain > 2) {
        if (display.length) display += ", ";
        display +=
          days[chainStart].substring(0, 3) +
          " - " +
          currentDay.substring(0, 3);
      } else {
        for (let j = chain; j > 0; j--) {
          if (display.length) display += ", ";
          display += days[i - j].substring(0, 3);
        }

        if (display.length) display += ", ";
        display += currentDay.substring(0, 3);
      }

      chainStart = i;
      chain = 0;
    }
  }

  return display;
}