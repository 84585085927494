<template>
  <div class="h-full w-full p-4">
    <div class="flex">
      <v-btn
        outlined
        @click="showEditProfile = true"
        class="text-center bg-green-mid text-white"
        >Edit Profile</v-btn
      >
      <ModelEdit
        v-model="showEditProfile"
        type="User"
        v-if="dbUser._id"
        :options="dbUser"
        v-on:saved="updateUser"
        v-on:closed="() => (showEditProfile = false)"
        :showDelete="false"
        title="Edit user"
        v-on:alert="(alert) => $emit('alert', alert)"
        width="600"
      />
      <v-spacer />
      <div><span class="text-2xl text-dark font-bold">Your profile</span></div>
      <v-spacer />
      <v-btn class="bg-gray-300" v-on:click="signOut">Sign out</v-btn>
    </div>
    <v-card class="py-6 px-12 mt-8 max-w-lg m-auto">
      <div>
        <div class="flex-1 flex flex-column justify-center align-center p-6">
          <div class="mb-4">
            <v-avatar size="150px" color="teal" class="text-6xl">
              <v-img
                v-if="dbUser.profileImageUrl"
                alt="Profile picture"
                :src="dbUser.profileImageUrl"
              />
              <span v-else class="white--text">{{ dbUser.initials }}</span>
            </v-avatar>
          </div>
        </div>
        <div class="flex-2">
          <div class="mb-4">
            <div>
              <span class="font-bold text-2xl"
                >{{ dbUser.firstName }} {{ dbUser.lastName }}</span
              >
              <span
                v-if="!dbUser.isEmployed"
                class="
                  text-sm
                  mx-2
                  px-2
                  py-1
                  bg-dark
                  text-light
                  rounded-lg
                  font-bold
                "
                >Inactive</span
              >
              <span
                v-if="dbUser.deleted"
                class="
                  text-sm
                  mx-2
                  px-2
                  py-1
                  bg-red-600
                  text-light
                  rounded-lg
                  font-bold
                "
                >Deleted</span
              >
            </div>

            <div class="font-weight-light text-gray-600">
              {{ dbUser.jobTitle }}
              <span v-if="dbUser.employmentType === 'contract'"
                >(contractor)</span
              >
            </div>

            <div class="font-weight-light text-gray-600">
              <div v-if="dbUser.squads && dbUser.squads[0]">
                <v-icon class="mb-1.5">mdi-account-group</v-icon>
                <span class="ml-2">{{ dbUser.squads[0].name }}</span>
              </div>
            </div>

            <div class="font-weight-light text-gray-600">
              <v-icon>mdi-email-outline</v-icon
              ><span class="ml-2">{{ dbUser.email }}</span>
            </div>
          </div>

          <div class="flex mb-4">
            <div class="flex-1">
              <div class="text-sm font-weight-light text-gray-600">
                Work hour limit
              </div>
              <div class="font-medium">{{ dbUser.workHoursPerWeek }}h/week</div>
            </div>
          </div>

          <div class="mb-4">
            <div class="text-sm font-weight-light text-gray-600">
              Working hours
            </div>
            <div
              v-bind:key="index"
              v-for="(timeShift, index) in dbUser.timeShifts"
            >
              <div class="font-weight-medium">
                <span>
                  {{ parseTimeShiftDays(timeShift.days) }}
                </span>
                :
                {{ timeShift.start.hour }}:<span
                  v-if="timeShift.start.minute < 10"
                  >0</span
                >{{ timeShift.start.minute }}
                <span class="text-xs">{{ timeShift.start.meridiem }}</span>

                - {{ timeShift.end.hour }}:<span
                  v-if="timeShift.end.minute < 10"
                  >0</span
                >{{ timeShift.end.minute }}
                <span class="text-xs">{{ timeShift.end.meridiem }}</span>
              </div>
            </div>
          </div>

          <!-- <div v-if="dbUser.skills && dbUser.skills.length">
            <div class="text-sm font-weight-light text-gray-600">Skills</div>
            <div class="flex flex-wrap">
              <div
                v-for="(skill, index) in dbUser.skills"
                :key="index"
                class="text-sm px-2 py-1 mr-2 mb-2 bg-green-100 text-green-700"
              >
                {{ skill.name }}
              </div>
            </div>
          </div> -->

          <!-- <div>
            <div>
              <span class="font-weight-light text-gray-600 mr-2"
                >Employment:</span
              >
              <span class="font-weight-medium">{{ employmentDuration }}</span>
            </div>
            <div>
              <span class="text-sm font-weight-light text-gray-600 mr-2"
                >Start date:</span
              >
              <span class="font-weight-medium">{{
                user.employments && user.employments[0]
                  ? format(user.employments[0].startDateMilliseconds, "PPPP")
                  : "N/A"
              }}</span>
            </div>
            <div
              v-if="
                user.employments &&
                user.employments[0] &&
                user.employments[0].endDateMilliseconds
              "
            >
              <span class="text-sm font-weight-light text-gray-600 mr-2"
                >End date:</span
              >
              <span class="font-weight-medium">{{
                user.employments && user.employments[0]
                  ? format(user.employments[0].endDateMilliseconds, "PPPP")
                  : "N/A"
              }}</span>
            </div>
          </div> -->
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import {
  format,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { signOut as signOutAPI } from "../../services/api";
import { signOut as signOutFirebase } from "../../services/firebase";
import { parseTimeShiftDays } from "../../services/user";

import ModelEdit from "../../components/ModelEdit.vue";
import { get } from "../../services/database";

export default {
  name: "AccountPage",
  props: {
    user: Object,
  },
  data() {
    return {
      dbUser: {},
      showEditProfile: false,
    };
  },
  components: {
    ModelEdit,
  },
  mounted() {
    this.getUser().then((user) => {
      this.dbUser = user;
    });
  },
  computed: {
    employmentDuration() {
      let employmentDuration = "";
      let startDate = new Date();
      let endDate = new Date();

      if (
        this.dbUser.currentEmployment &&
        this.dbUser.currentEmployment &&
        this.dbUser.currentEmployment.startDateMilliseconds
      ) {
        startDate = new Date(
          this.dbUser.currentEmployment.startDateMilliseconds
        );
      }

      if (
        this.dbUser.currentEmployment &&
        this.dbUser.currentEmployment &&
        this.dbUser.currentEmployment.endDateMilliseconds
      ) {
        endDate = new Date(this.dbUser.currentEmployment.endDateMilliseconds);
      }

      const employmentDurationDifferenceInYears = differenceInYears(
        endDate,
        startDate
      );

      let employmentDurationDifferenceInMonths = differenceInMonths(
        endDate,
        startDate
      );
      if (employmentDurationDifferenceInMonths > 12)
        employmentDurationDifferenceInMonths =
          employmentDurationDifferenceInMonths % 12;

      let employmentDurationDifferenceInDays = differenceInDays(
        endDate,
        startDate
      );
      // TODO this is not accurate as not all months have 30 days
      if (employmentDurationDifferenceInDays > 30)
        employmentDurationDifferenceInDays =
          employmentDurationDifferenceInDays % 30;

      if (employmentDurationDifferenceInYears) {
        employmentDuration += employmentDurationDifferenceInYears;
        employmentDuration +=
          employmentDurationDifferenceInYears > 1 ? " years" : " year";
      }

      if (employmentDurationDifferenceInMonths) {
        if (
          employmentDurationDifferenceInYears &&
          employmentDurationDifferenceInDays
        ) {
          employmentDuration += ", ";
        } else if (employmentDurationDifferenceInYears) {
          employmentDuration += " and ";
        }

        employmentDuration += employmentDurationDifferenceInMonths;
        employmentDuration +=
          employmentDurationDifferenceInMonths > 1 ? " months" : " month";
      }

      if (employmentDurationDifferenceInDays) {
        if (
          employmentDurationDifferenceInYears ||
          employmentDurationDifferenceInDays
        ) {
          employmentDuration += " and ";
        }
        employmentDuration += employmentDurationDifferenceInDays;
        employmentDuration +=
          employmentDurationDifferenceInDays > 1 ? " days" : " day";
      }

      return employmentDuration;
    },
  },
  methods: {
    format,
    parseTimeShiftDays,
    getUser() {
      return get("User", "full", this.user._id);
    },
    updateUser: function () {
      this.getUser().then((user) => {
        this.dbUser = user;
        this.showEditProfile = false;
      });
    },
    signOut() {
      signOutAPI()
        .then(() => {
          return signOutFirebase();
        })
        .then(() => {
          this.$router.push("/login");
        });
    },
  },
};
</script>