<template>
  <div class="flex-1 ml-4">
    <v-data-table
      class="mt-0.5"
      :headers="headers"
      :items="users"
      :footer-props="footerOptions"
      no-data-text="No users selected"
      item-key="_id"
    >
      <template v-slot:[`item.fullName`]="{ item }">
        <v-avatar size="36px" color="teal">
          <v-img
            v-if="item.profileImageUrl"
            alt="Avatar"
            :src="item.profileImageUrl"
          />
          <span v-else class="white--text">{{ item.initials }}</span>
        </v-avatar>
        <span class="ml-2">{{ item.fullName }}</span>
      </template>
      <template v-slot:[`item.squads`]="{ item }">
        <span>{{ getSquadsList(item) }}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { get, handleError } from "../../services/database";

export default {
  name: "UserList",
  props: {
    value: Array,
  },
  data() {
    return {
      users: this.value || [],
      itemsPerPage: 5,
      loading: false,
      currentPage: 0,
      totalCount: 0,
      footerOptions: {
        "items-per-page-options": [5],
      },
      headers: [
        {
          text: "Name",
          value: "fullName",
        },
        {
          text: "Job Title",
          value: "jobTitle",
        },
        // {
        //   text: "Email",
        //   value: "email",
        // },
        {
          text: "Squad(s)",
          value: "squads",
        },
      ],
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.users = newValue
      },
      deep: true
    }
  },
  methods: {
    getUsers(page = 0) {
      this.loading = true;
      return get("Users", "overview", null, {
        itemsPerPage: this.itemsPerPage,
        page,
        searchTerm: this.searchTerm,
      })
        .then(({ data, totalCount }) => {
          this.users = data;
          this.totalCount = totalCount;
        })
        .catch((error) => {
          handleError(error, this);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSquadsList(user) {
      const squadListLimit = 1;
      if (user.squads.length === 0) {
        return "-";
      } else if (user.squads.length > squadListLimit) {
        return (
          user.squads
            .slice(0, squadListLimit)
            .map((squad) => squad.name)
            .join(", ") + ` + ${user.squads.length - squadListLimit} more`
        );
      } else {
        return user.squads.map((squad) => squad.name).join(", ");
      }
    },
  }
};
</script>