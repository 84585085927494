export function capitalise(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getObjectAttributes(object, schemaFields, modelQueriesOverride = null) {
  const newAttributesObject = {}

  Object.keys(schemaFields).forEach((schemaFieldToSave) => {
    if (Array.isArray(object[schemaFieldToSave]) && !modelQueriesOverride.base.includes(schemaFieldToSave)) {
      newAttributesObject[schemaFieldToSave] = object[schemaFieldToSave].map((arrayItem) => {

        const newArrayItem = {}
        Object.keys(modelQueriesOverride[schemaFieldToSave]).forEach((key) => {
          if (key === 'base') {
            modelQueriesOverride[schemaFieldToSave][key].forEach((childKey) => {
              newArrayItem[childKey] = arrayItem[childKey]
            })
          } else {
            Object.keys(modelQueriesOverride[schemaFieldToSave][key]).forEach((childKey) => {
              if (childKey === 'base') {
                modelQueriesOverride[schemaFieldToSave][key][childKey].forEach((grandChildKey) => {
                  if (!newArrayItem[key]) {
                    newArrayItem[key] = {
                      [grandChildKey]: arrayItem[key][grandChildKey]
                    }
                  } else {
                    newArrayItem[key][grandChildKey] = arrayItem[key][grandChildKey]
                  }
                })
              } else {
                Object.keys(modelQueriesOverride[schemaFieldToSave][childKey]).forEach((grandChildKey) => {
                  console.log('childKey ------ what?', grandChildKey)
                })
              }
            })
          }
        })

        return newArrayItem
      })
    } else {
      newAttributesObject[schemaFieldToSave] = object[schemaFieldToSave]
    }
  })

  return newAttributesObject
}