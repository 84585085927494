<template>
  <v-date-picker
    ref="calendar"
    v-model="dates"
    class="flex"
    multiple
    range
    no-title
    elevation="1"
    landscape
    reactive
    width="100%"
    color="#42c4b6"
    @update:active-picker="updateStartEndClass"
    @change="updateStartEndClass"
  />
</template>
<script>
import isAfter from "date-fns/isAfter";

export default {
  name: "DatePicker",
  props: {
    value: Array,
  },
  data: function () {
    // let dates = [];
    // if (this.value) {
    //   dates = this.value.map((dateInMilliseconds) => {
    //     if (dateInMilliseconds) {
    //       return format(new Date(dateInMilliseconds), "yyyy-MM-dd");
    //     } else {
    //       return dateInMilliseconds;
    //     }
    //   });
    // }

    return {
      dates: this.value || [],
    };
  },
  mounted() {
    this.updateStartEndClass();
  },
  methods: {
    updateStartEndClass() {
      this.$nextTick(() => {
        if (this.value.length <= 1 || this.value[0] == undefined) return;

        const calendar = this.$refs.calendar.$el;
        const activeDates = calendar.getElementsByClassName("v-btn--active");
        const allDates = calendar.getElementsByClassName("v-btn__content");

        if (activeDates.length) {
          const lastIndex = activeDates.length - 1;

          activeDates[0].classList.add("start");
          activeDates[lastIndex].classList.add("end");

          // TODO need to get sprint duration from squad
          const sprintDuration = 14;

          if (activeDates.length < sprintDuration) {
            // not all of sprint is highlighted
            // determine if the start or end shown
            if (activeDates[0].textContent == 1) {
              // end is shown, add fade style to first active date
              activeDates[0].classList.add("continued");
            } else if (
              allDates[allDates.length - 1] == activeDates[0].textContent
            ) {
              activeDates[0].classList.add("to-be-continued");
            }
          }
        }
      });
    },
  },
  watch: {
    dates: function (dates) {
      let startDateMilliseconds;
      let endDateMilliseconds;

      if (!dates[1]) {
        startDateMilliseconds = dates[0];
        endDateMilliseconds = dates[0];
      } else if (isAfter(new Date(dates[0]), new Date(dates[1]))) {
        startDateMilliseconds = dates[1];
        endDateMilliseconds = dates[0];
      } else {
        startDateMilliseconds = dates[0];
        endDateMilliseconds = dates[1];
      }

      this.$emit("input", [startDateMilliseconds, endDateMilliseconds]);
    },
  },
};
</script>
<style>
.v-picker .v-date-picker-table td button {
  width: 100%;
  border-radius: 0;
}

table td button.v-btn--active.start {
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
}

table td button.v-btn--active.start.continued {
  border-radius: unset;
  background-image: linear-gradient(
    to right,
    white,
    theme("colors.green.light") 30%
  );
}

table td button.v-btn--active.start.to-be-continued {
  border-radius: unset;
  background-image: linear-gradient(
    to left,
    white,
    theme("colors.green.light") 70%
  );
}

table td button.v-btn--active.end {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
}
</style>