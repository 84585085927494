var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-4",attrs:{"id":"squads-page"}},[_c('v-card',[_c('div',{staticClass:"p-4"},[_c('div',[_c('span',{staticClass:"text-2xl text-dark font-bold"},[_vm._v("Squads")])]),_c('div',{staticClass:"flex mt-4"},[_c('v-text-field',{staticClass:"p-0 m-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-spacer'),_c('div',{staticClass:"flex flex-row-reverse"},[_c('ModelEdit',{attrs:{"type":"Squad","title":"Create a new squad","width":"900"},on:{"saved":_vm.squadSaved,"alert":function (alert) { return _vm.$emit('alert', alert); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"bg-green-light",attrs:{"dark":""}},on),[_vm._v("Create new")])]}}])})],1)],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.squads,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalCount,"footer-props":_vm.footerOptions,"loading":_vm.loading,"disable-sort":true,"show-select":"","item-key":"_id"},on:{"update:page":_vm.onSquadPageUpdate,"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.sprintCapacity",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.capacity.remaining.sprint)+" / "+_vm._s(item.capacity.total.sprint))])]}},{key:"item.previousSprintCapacity",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.capacity.previous.sprint)+" ")])]}},{key:"item.members",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.users.length))])]}}],null,true),model:{value:(_vm.selectedSquads),callback:function ($$v) {_vm.selectedSquads=$$v},expression:"selectedSquads"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }