<template>
  <div>
    <!-- mini-variant -->
    <v-navigation-drawer
      dark
      permanent
      stateless
      app
      mini-variant-width="70"
      :mini-variant="useMiniNavigationDrawer"
      class="bg-dark flex flex-col"
    >
      <v-layout column fill-height>
        <img
          src="@/assets/images/logo.svg"
          alt="trackk logo"
          class="w-11/12 p-3 m-auto"
        />

        <v-list dense nav>
          <v-tooltip
            right
            v-for="item in items"
            :key="item.title"
            :disabled="!useMiniNavigationDrawer"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item link :to="item.path" v-bind="attrs" v-on="on">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>

                <div
                  :class="
                    item.path === $route.path
                      ? 'selected bg-green-light'
                      : 'not-selected'
                  "
                ></div>
              </v-list-item>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </v-list>

        <v-spacer />

        <v-list dense nav>
          <v-tooltip right :disabled="!useMiniNavigationDrawer">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                link
                :to="`/accounts/${user._id}`"
                v-bind="attrs"
                v-on="on"
                class="p-0 m-0"
              >
                <v-list-item-content class="p-2">
                  <v-avatar size="38px" color="teal" class="text-6xl">
                    <v-img
                      v-if="user.profileImageUrl"
                      alt="Profile picture"
                      :src="user.profileImageUrl"
                    />
                    <span v-else class="white--text">{{ user.initials }}</span>
                  </v-avatar>

                  <!-- <v-list-item
                link
                :to="`/accounts/${user._id}`"
                v-bind="attrs"
                v-on="on"
                class="p-0 m-0"
              >
                <v-list-item-content class="p-2">
                  <div
                    class="
                      bg-center bg-cover bg-no-repeat
                      h-10
                      rounded-lg
                      m-auto
                    "
                    :style="{
                      'background-image': `url('${
                        user.profileImageUrl || missingProfileImage
                      }')`,
                      'background-color': false ? '#3b938d' : 'initial',
                    }"
                  ></div>
                </v-list-item-content>

                <div
                  :class="
                    `user/${user._id}` === $route.path
                      ? 'selected bg-green-light'
                      : 'not-selected'
                  "
                ></div>
              </v-list-item> -->
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Your account</span>
          </v-tooltip>
        </v-list>
      </v-layout>
    </v-navigation-drawer>

    <v-main class="h-full bg-light">
      <router-view
        v-if="!layoutLoading"
        v-show="!loading"
        v-on:alert="(alert) => $emit('alert', alert)"
        v-on:loading="setLoading"
        :loading="loading"
        :user="user"
        :currentUser="user"
      />
      <v-progress-circular
        v-if="loading || layoutLoading"
        :size="50"
        indeterminate
        class="flex mx-auto mt-4"
      />
    </v-main>
  </div>
</template>

<script>
import missingProfileImage from "../assets/images/missing_profile.svg";
import { handleError } from "../services/database";

import { checkUser } from "../services/user";

export default {
  name: "AuthenticatedLayout",
  data: () => ({
    useMiniNavigationDrawer: true,
    user: {},
    layoutLoading: false,
    loading: false,
    missingProfileImage,
    alerts: [],
    items: [
      { title: "Dashboard", icon: "mdi-view-dashboard", path: "/dashboard" },
      { title: "Squads", icon: "mdi-account-group", path: "/squads" },
      { title: "Users", icon: "mdi-account-box-outline", path: "/users" },
      // { title: "Objectives", icon: "mdi-chart-timeline", path: "/objectives" },
    ],
  }),
  methods: {
    setLoading(isLoading) {
      this.loading = isLoading;
    },
  },
  mounted() {
    this.layoutLoading = true;
    checkUser()
      .then((user) => {
        if (user) {
          this.user = user;
        } else if (!user) {
          this.$emit("alert", { type: "error", message: "Not logged in" });
          this.$router.replace("/login");
        }
      })
      .catch((error) => {
        handleError(error, this);
      })
      .finally(() => {
        this.layoutLoading = false;
      });
  },
};
</script>
<style>
header {
  /* needs to be the same as the min-variant-width for v-navigation-drawer */
  left: 70px;
}

.theme--dark.v-list-item--active:hover::before,
.theme--dark.v-list-item--active::before {
  opacity: 0;
}

.not-selected {
  width: 0;
}

.selected {
  width: 4px !important;
  height: 100% !important;
  left: -8px;
  z-index: 10;
  clip: unset !important;
  transition: width 0.2s ease-out;
}
</style>