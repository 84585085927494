<template>
  <div>
    <div class="query flex">
      <v-text-field
        outlined
        :placeholder="placeholder"
        class="flex-3"
        v-model="searchTerm"
        v-on:input="search"
      />
      <v-select
        :items="fields"
        v-model="field"
        v-on:input="() => search(searchTerm)"
        solo
        class="fill-input flex-1 ml-2 h-14 full-height-input mt-0"
        hide-details
      ></v-select>
    </div>
    <div class="flex">
      <div class="flex-1">
        <div class="text-lg mb-2">Search results</div>
        <div class="flex flex-wrap">
          <component
            class="w-64 m-auto mb-4"
            v-for="(modelObject, index) in results"
            :key="index"
            v-bind:is="modelCardComponent"
            :value="modelObject"
            disable-nav
            v-on:click="() => select(modelObject)"
          ></component>
        </div>
      </div>
      <div class="flex-1">
        <div class="text-lg mb-2">Selected</div>
        <div class="flex flex-wrap">
          <component
            class="w-64 m-auto mb-4"
            v-for="(modelObject, index) in selected"
            :key="index"
            v-bind:is="modelCardComponent"
            :value="modelObject"
            disable-nav
            v-on:click="() => remove(modelObject)"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { capitalise } from "../../services/utils";
import { search } from "../../services/database";

let cancelTokenSource;

export default {
  name: "QueryData",
  props: {
    modelType: String,
    value: Array,
    fields: Array,
    fieldDefault: String || Object,
    placeholder: String,
    resultsDefault: { type: Array, default: () => [] },
  },
  created: function () {
    const componentName = `${capitalise(this.modelType)}QueryCard`;
    this.$options.components[componentName] = () =>
      import(`./${componentName}`);
  },
  data: function () {
    return {
      searchTerm: "",
      field: this.fieldDefault,
      results: this.resultsDefault,
      originalSearchResults: this.resultsDefault,
      modelCardComponent: `${capitalise(this.modelType)}QueryCard`,
      selected: [],
    };
  },
  methods: {
    search(text) {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
      cancelTokenSource = axios.CancelToken.source();
      search(this.modelType, text, this.field, cancelTokenSource.token).then(
        (searchResults) => {
          this.originalSearchResults = searchResults;
          this.results = searchResults.filter((result) => {
            const selectedIds = this.selected.map((selected) => selected._id);
            return !selectedIds.includes(result._id);
          });
        }
      );
    },
    select(modalObject) {
      this.selected.push(modalObject);
      this.results = this.results.filter((result) => {
        const selectedIds = this.selected.map((selected) => selected._id);
        return !selectedIds.includes(result._id);
      });
      this.$emit(
        "input",
        this.selected.map((item) => item._id)
      );
    },

    remove(modalObject) {
      const indexToRemove = this.selected.findIndex(
        (item) => item._id === modalObject._id
      );

      this.selected.splice(indexToRemove, 1);

      const originalSearchResultIds = this.originalSearchResults.map(
        (result) => result._id
      );
      if (originalSearchResultIds.includes(modalObject._id)) {
        const indexToPutBack = originalSearchResultIds.findIndex(
          (resultId) => resultId === modalObject._id
        );

        this.results.splice(indexToPutBack, 0, modalObject);
      }
    },
  },
};
</script>
<style>
.query .fill-input .v-input__control,
.query .fill-input .v-input__control > * {
  height: 100%;
}
</style>