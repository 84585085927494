<template>
  <div id="squad-page" class="h-full flex">
    <div class="flex-1 mx-4 flex flex-column">
      <div class="mt-6">
        <div class="inline font-bold text-2xl mr-4">{{ squad.name }}</div>

        <ModelEdit
          class="inline"
          type="Squad"
          v-if="squad._id"
          :options="squad"
          v-on:saved="updateSquad"
          title="Edit squad"
          width="1000"
          v-on:alert="(alert) => $emit('alert', alert)"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" outlined class="text-center text-green-mid"
              >Edit</v-btn
            >
          </template>
        </ModelEdit>
      </div>

      <div v-if="squadSkills.length" class="mt-4">
        <div class="text-sm font-weight-light text-gray-600">Skills</div>
        <div class="flex flex-wrap">
          <div
            v-for="(skill, index) in squadSkills"
            :key="index"
            class="text-sm px-2 py-1 mr-2 mb-2 bg-green-100 text-green-700"
          >
            {{ skill.name }}
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="text-sm font-weight-light text-gray-600">Members</div>
        <v-data-table
          :headers="headers"
          :items="squad.users"
          :footer-props="footerOptions"
          :disable-sort="true"
          @click:row="gotToUser"
          item-key="_id"
          no-data-text="No users found"
        >
          <template v-slot:[`item.fullName`]="{ item }">
            <v-avatar size="36px" color="teal">
              <v-img
                v-if="item.profileImageUrl"
                alt="Avatar"
                :src="item.profileImageUrl"
              />
              <span v-else class="white--text">{{ item.initials }}</span>
            </v-avatar>
            <span class="ml-2">{{ item.fullName }}</span>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="flex-3 flex my-4 mr-4 flex-column">
      <v-card class="flex-1 mb-4 h-full p-4">
        <Chart
          :labels="totalCapacityChartLabels"
          :datasets="totalCapacityChartDatasets"
          :options="chartOptions"
        />
      </v-card>
      <v-card class="flex-1 p-4">
        <div class="text-xl mb-2">Sprints</div>

        <v-tabs height="36px" color="teal" v-model="squadSprintTab">
          <v-tab key="past">Past</v-tab>
          <v-tab key="upcoming">Upcoming</v-tab>
        </v-tabs>

        <v-tabs-items v-model="squadSprintTab">
          <v-tab-item key="past">
            <SquadSprintTable :items="squadSprintsPast" />
          </v-tab-item>
          <v-tab-item key="upcoming">
            <SquadSprintTable :items="squadSprintsUpcoming" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";

import { get, handleError } from "../../services/database";

import ModelEdit from "../../components/ModelEdit";
import Chart from "../../components/Chart.vue";
import SquadSprintTable from "../../components/authenticated/SquadSprintTable";

export default {
  name: "SquadPage",
  components: {
    ModelEdit,
    Chart,
    SquadSprintTable,
  },
  data: function () {
    return {
      squad: {},
      reports: null,

      squadSprintTab: "past",
      squadSprintsPast: [],
      squadSprintsUpcoming: [],

      footerOptions: {
        "items-per-page-options": [100],
      },

      headers: [
        {
          text: "Name",
          value: "fullName",
        },
        {
          text: "Job Title",
          value: "jobTitle",
        },
        // {
        //   text: "Email",
        //   value: "email",
        // },
      ],
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            onClick: (e) => e.stopPropagation(),
          },
        },
      },
    };
  },
  mounted() {
    Promise.all([this.getSquad(), this.getReports()]).finally(() => {
      this.loading = false;
    });
  },
  computed: {
    squadSkills() {
      const skills = [];
      if (this.squad && this.squad.users) {
        this.squad.users.forEach((user) => {
          if (user.skills) {
            user.skills.forEach((skill) => {
              const existingSkill = skills.find(
                (existingSkill) => existingSkill.name === skill.name
              );
              if (!existingSkill) {
                skills.push(skill);
              }
            });
          }
        });
      }

      return skills;
    },
    totalCapacityChartLabels() {
      if (this.reports) {
        const labels = this.reports.totalCapacity.map((report) =>
          format(new Date(report.dateMilliseconds), "MMM")
        );

        return labels;
      }
      return [];
    },
    totalCapacityChartDatasets() {
      let dataTotal = [];
      // let dataActual = [];

      if (this.reports) {
        dataTotal = this.reports.totalCapacity.map((report) => report.value);
        // dataActual = this.reports.totalCapacity.map((report) => report.value);
      }

      return [
        {
          label: "Total Capacity",
          data: dataTotal,
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.3,
        },
        // {
        //   label: "Actual Capacity",
        //   data: dataActual,
        //   fill: false,
        //   borderColor: "rgb(72,61,139)",
        //   tension: 0.3,
        // },
      ];
    },
  },
  methods: {
    getSquad() {
      return Promise.all([
        get("Squad", "full", this.$route.params.id),
        get("squadSprints", "full", this.$route.params.id, {
          pastSprints: 4,
        }),
        get("squadSprints", "full", this.$route.params.id, {
          upcomingSprints: 4,
        }),
      ])
        .then(([squad, squadSprintsPast, squadSprintsUpcoming]) => {
          this.squad = squad;

          this.squadSprintsPast = squadSprintsPast;
          this.squadSprintsUpcoming = squadSprintsUpcoming;
        })
        .catch((error) => {
          handleError(error, this);
        });
    },
    getReports() {
      get("SquadReport", "full", this.$route.params.id, {
        type: "monthly",
        count: 6,
      })
        .then((squadReport) => {
          this.reports = squadReport;
        })
        .catch((error) => {
          handleError(error, this);
        });
    },
    updateSquad() {
      // TODO need a better way to sort out loading on this page
      // this.$emit("loading", true);
      this.getSquad();
    },
    gotToUser(item) {
      this.$router.push(`/users/${item._id}`);
    },
  },
};
</script>
<style>
.viewport-height {
  height: calc(100vh - 64px);
}

.custom-green--text {
  color: #42c4b6;
  caret-color: #42c4b6;
}

.v-data-table.theme--light {
  background-color: unset;
}

#squad-page .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
#squad-page .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0;
}

#squad-page tbody tr:hover:not(#no-data) {
  background-color: #eeeeee;
}

#squad-page tbody tr {
  background-color: unset;
  cursor: pointer;
}
</style>
