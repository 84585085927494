<template>
  <v-app id="app" class="bg-light">
    <div class="absolute right-4 top-4 alert">
      <v-alert
        dismissible
        border-left
        v-for="(alert, index) in alerts"
        :key="index"
        :type="alert.type"
        v-on:input="() => removeAlert(alert)"
      >
        <span class="mx-2 font-bold">{{ alert.message }}</span>
      </v-alert>
    </div>
    <router-view v-on:alert="(alert) => showAlert(alert)" class="h-full" />
  </v-app>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      alerts: [],
    };
  },
  methods: {
    showAlert: function (alert) {
      this.alerts.push(alert);

      if (!alert.persist) {
        setTimeout(() => {
          this.removeAlert(alert);
        }, 3000);
      }
    },
    removeAlert: function (alert) {
      this.alerts = this.alerts.filter(
        (existingAlert) => existingAlert != alert
      );
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// checboxes
#app .mdi-checkbox-marked.theme--light {
  color: #42c4b6 !important;
  caret-color: #42c4b6 !important;
}

#app .v-list-item--active.theme--light {
  color: #3b938d !important;
  caret-color: #3b938d !important;
}

.alert {
  z-index: 203;
}
.v-data-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
