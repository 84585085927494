<template>
  <v-dialog :value="value" max-width="400">
    <v-card>
      <v-card-title class="headline"> Email sent </v-card-title>
      <v-card-text>
        An email has been sent to {{ email }}. Please click the link in the
        email to sign in.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          <span class="text-green-mid">OK</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    email: String,
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
  },
};
</script>