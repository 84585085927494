<template>
  <div class="flex flex-column h-screen">
    <div class="flex-1 flex justify-center align-center">
      <ModelEdit
        v-model="showSignUpModal"
        type="SignUp"
        :options="user"
        :public="true"
        v-on:saved="signUpSuccess"
        v-on:closed="showSignUpModal = false"
        title="Create an account"
        v-on:alert="(alert) => $emit('alert', alert)"
        width="600"
      />
      <div class="flex-1 p-4 max-w-2xl">
        <div class="mb-6 text-center">
          <img
            src="@/assets/images/logo_full.svg"
            class="m-auto w-64"
            alt="trackk logo"
          />
          <div class="text-4xl font-bold">Sign in to your account</div>
        </div>
        <div class="flex">
          <div class="flex-1 mr-4">
            <AuthWithEmailForm />
          </div>
          <div>
            <div class="flex flex-column align-center">
              <div class="flex-1 w-full">
                <AuthWithGoogleButton
                  @click="signInWithGoogle"
                  :Loading="loadingGoogle | loading"
                />
              </div>
              <div class="flex-1 w-full mt-4">
                <AuthWithMicrosoftButton
                  @click="signInWithMicrosoft"
                  :Loading="loadingMicrosoft | loading"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 text-center">
          <span>Don't have an account?</span>
          <v-btn
            id="sign-up-btn"
            plain
            text
            color="primary"
            class="p-0 ml-1"
            :ripple="false"
            @click="isSignUpModalOpen = true"
          >
            <span>Sign up</span>
          </v-btn>
        </div>
      </div>

      <SignUpModal v-model="isSignUpModalOpen" />
    </div>

    <footer class="text-xs text-center mb-4">
      <div class="mb-2">Terms and conditions</div>
      <div>© 2022 Trackk</div>
    </footer>
  </div>
</template>
<script>
import breakpoints from "../../services/breakpoints";
import ModelEdit from "../../components/ModelEdit.vue";
import { inviteCheck } from "../../services/api";
import AuthWithEmailForm from "./components/AuthWithEmailForm.vue";
import AuthWithGoogleButton from "./components/AuthWithGoogleButton.vue";
import AuthWithMicrosoftButton from "./components/AuthWithMicrosoftButton.vue";

import { signInWithEmailLink, companyLogin } from "../../services/api";

import {
  signInWithGoogle as signInWithGoogleFirebase,
  signInWithMicrosoft as signInWithMicrosoftFirebase,
} from "../../services/firebase";
import { isAuthenticated } from "../../services/user";

import SignUpModal from "./components/SignUpModal.vue";

export default {
  name: "LoginPage",
  components: {
    ModelEdit,
    AuthWithEmailForm,
    AuthWithGoogleButton,
    AuthWithMicrosoftButton,
    SignUpModal,
  },
  data: () => ({
    isSignUpModalOpen: false,
    showSignUpModal: false,
    loadingEmail: false,
    loadingGoogle: false,
    loadingMicrosoft: false,
    breakpoints,
    user: null,
  }),
  mounted() {
    if (this.$route.query.email && this.$route.query.sign_in) {
      this.attemptSignIn(this.$route.query.email);
    } else if (
      this.$route.query.email &&
      this.$route.query.u &&
      this.$route.query.invite
    ) {
      this.getInvitedUser(this.$route.query.email, this.$route.query.u).then(
        (response) => {
          this.user = response.data;
          this.showSignUpModal = true;
        }
      );
    } else {
      this.loadingEmail = true;
      isAuthenticated()
        .then((isAuthenticated) => {
          if (isAuthenticated) {
            this.$router.replace("/dashboard");
          } else {
            window.addEventListener("resize", this.resetBreakpoints);
          }
        })
        .finally(() => {
          this.loadingEmail = false;
        });
    }
  },
  computed: {
    loading() {
      return this.loadingEmail || this.loadingGoogle || this.loadingMicrosoft;
    },
  },
  methods: {
    signInWithGoogle() {
      signInWithGoogleFirebase()
        .then(this.onSignInSuccess)
        .catch(this.onSignInError);
    },
    signInWithMicrosoft() {
      signInWithMicrosoftFirebase()
        .then(this.onSignInSuccess)
        .catch(this.onSignInError);
    },
    attemptSignIn(email) {
      this.loadingEmail = true;
      signInWithEmailLink(email)
        .then((results) => {
          this.loading = false;
          if (results.error) {
            this.$emit("alert", {
              type: "error",
              message: results.error.message,
            });
            this.$router.replace({ path: "/login", query: null });
          } else {
            if (results.code === "create_new") {
              this.showSignUpModal = true;
              this.user = results.data.user;
            } else if (results.currentEmployers.length === 1) {
              return companyLogin(results.currentEmployers[0]._id).then(() => {
                this.loadingGoogle = false;
                this.$router.replace({ path: "/dashboard", query: null });
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.confirmingEmail = false;
          this.showConfirmEmail = true;

          if (error.code === "auth/invalid-action-code") {
            this.$emit("alert", {
              type: "error",
              message: error.message,
            });
          } else {
            this.$emit("alert", {
              type: "error",
              message: "Your email and your unique login link do not match",
            });
          }
        })
        .finally(() => {
          this.loadingEmail = false;
        });
    },
    onSignInError(error) {
      console.log("error", error);
    },
    onSignInSuccess(results) {
      if (results.code === "create_new") {
        this.showSignUpModal = true;
        this.user = results.data.user;
      } else if (results.currentEmployers.length === 1) {
        return companyLogin(results.currentEmployers[0]._id).then(() => {
          this.loadingGoogle = false;
          this.$router.replace({ path: "/dashboard", query: null });
        });
      }
    },
    resetBreakpoints() {
      this.breakpoints = Object.assign({}, breakpoints);
    },
    signUpSuccess(response) {
      // window.localStorage.setItem("emailForSignIn", user.email);

      return companyLogin(response.companyId).then(() => {
        this.loadingGoogle = false;
        this.$router.replace({ path: "/dashboard", query: null });
      });
    },
    getInvitedUser(email, userHash) {
      return inviteCheck(email, userHash);
    },
  },
};
</script>
<style>
#sign-up-btn.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: 1 !important;
}

#sign-up-btn.v-btn {
  text-transform: unset;
  font-size: 16px;
}
</style>