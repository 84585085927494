<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div>
    <div v-for="(schemaField, index) in schema" :key="index">
      <SchemaFieldMultiple
        v-if="schemaField.type == 'multiple'"
        :field="schemaField"
        :update="updateParent || update"
        :value="value"
        v-on:alert="(alert) => $emit('alert', alert)"
      />
      <SchemaFieldConditional
        v-else-if="schemaField.type == 'conditional'"
        :field="schemaField"
        :update="updateParent || update"
        :currentValue="currentValue"
        :value="value"
        v-on:alert="(alert) => $emit('alert', alert)"
      />
      <SchemaField
        v-else
        :value="schemaField"
        :update="updateParent || update"
        :previousValue="getPreviousValue(schemaField)"
        v-on:alert="(alert) => $emit('alert', alert)"
      />
    </div>
  </div>
</template>
<script>
// import { merge } from "lodash";
import SchemaField from "./SchemaField";
import SchemaFieldMultiple from "./SchemaFieldMultiple";
import SchemaFieldConditional from "./SchemaFieldConditional";

export default {
  name: "SchemaFields",
  props: {
    value: Object || Array,
    schema: Array,
    updateParent: Function,
  },
  components: {
    SchemaField,
    SchemaFieldMultiple,
    SchemaFieldConditional,
  },
  // watch: {
  //   value: function (newValue) {
  //     this.currentValue = newValue;
  //   },
  // },
  data: function () {
    return {
      currentValue: Object.assign({}, this.value),
    };
  },
  methods: {
    update: function (field, value) {
      // const newData = {};

      if (Array.isArray(field)) {
        field.forEach((field, index) => {
          this.currentValue[field] = value[index];
        });
      } else if (field.includes(".")) {
        const splitFields = field.split(".");
        splitFields.reduce((object, attribute, index) => {
          if (index < splitFields.length - 1) {
            if (!object[attribute]) {
              return (object[attribute] = {});
            } else {
              return object[attribute];
            }
          } else {
            return (object[attribute] = value);
          }
        }, this.currentValue);
      } else {
        this.currentValue[field] = value;
      }

      // this.data = merge(this.data, newData);
      this.$emit("input", this.currentValue);
    },
    getPreviousValue(schemaField) {
      if (
        !!this.value &&
        schemaField.model &&
        schemaField.model.includes(".")
      ) {
        const splitFields = schemaField.model.split(".");
        const test = splitFields.reduce((object, currentValue, currentIndex) => {
          if (Array.isArray(object)) {
            return object.map((obj) => obj[currentValue]);
          } else if (!object[currentValue]) {
            if (splitFields.length -1 != currentIndex) {
              return (object[currentValue] = {});
            } else {
              return null
            }
          } else {
            return object[currentValue];
          }
        }, this.value);

        return test;
      } else if (!!this.value && this.value[schemaField.model]) {
        return this.value[schemaField.model];
      } else if (
        !!this.value &&
        Array.isArray(schemaField.model) &&
        schemaField.model.length
      ) {
        return schemaField.model.map((model) => {
          return this.value[model];
        });
      } else {
        return null;
      }
    },
  },
};
</script>