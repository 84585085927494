<template>
  <div class="flex justify-center m-4 mb-8">
    <!-- <v-file-input v-model="imageToUpload"></v-file-input> -->

    <!--
      :name="uploadFieldName"
      :disabled="isSaving"

    @change="
      filesChange($event.target.name, $event.target.files);
      fileCount = $event.target.files.length;
    " -->
    <input
      type="file"
      accept="image/*"
      class="file-input"
      @change="selectFile($event.target.files)"
    />
    <!-- <div class="upload-image-ui">{{ label }}</div> -->

    <v-avatar size="150px" color="teal" class="text-lg upload-image-ui">
      <v-img referrerpolicy="no-referrer" v-if="imageUrl" alt="Profile picture" :src="imageUrl" />
      <span v-else class="white--text">{{ label }}</span>
    </v-avatar>

    <div v-if="imageUrl || imageToUpload" class="relative">
      <div class="change-file">
        <span>Change picture</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImageUploader",
  props: {
    value: [File, String],
    label: String,
  },
  data() {
    return {
      // TODO need to figure out how to upload with one variable but display with another
      // TODO have upload types that end in "Url" have the ending "Url" removed?
      imageUrl:
        this.value && typeof this.value === "object"
          ? URL.createObjectURL(this.value)
          : this.value,
      imageToUpload: null,
    };
  },
  watch: {
    imageToUpload: {
      // deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    selectFile(fileList) {
      const fileToUpload = fileList[0];
      if (fileToUpload) {
        this.imageToUpload = fileToUpload;
        this.imageUrl = URL.createObjectURL(fileToUpload);
      }
    },
  },
};
</script>
<style>
.upload-image-ui,
.file-input {
  border-radius: 50%;
  height: 150px;
  width: 150px;

  cursor: pointer;
}

.upload-image-ui {
  background: #c3dedb;
  text-align: center;
  line-height: 150px;
}

.file-input {
  z-index: 1;
  opacity: 0;
  position: absolute;
}

.change-file {
  position: absolute;
  left: 50%;
  transform: translate(-100%, 0%);
  height: 150px;
  width: 150px;
  border-radius: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 35%, transparent 35%);

  text-align: center;
  color: white;
  font-size: 16px;
}

.change-file span {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
}
</style>