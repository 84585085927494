const listeners = {}

export function registerListener(model, callback) {
  if (listeners[model]) {
    const newLength = listeners[model].push(callback)
    const callbackIndex = newLength - 1

    return () => {
      removeListener(model, callbackIndex)
    }
  } else {
    listeners[model] = [
      callback
    ]

    return () => {
      removeListener(model, 0)
    }
  }
}

function removeListener(model, callbackIndex) {
  if (listeners[model] && listeners[model][callbackIndex]) {
    listeners[model].splice(callbackIndex, 1)
  }
}

export function triggerListeners(model, value) {
  if (listeners[model]) {
    listeners[model].forEach((callback) => {
      callback(value)
    })
  }
}

export default {
  registerListener,
  triggerListeners,
}