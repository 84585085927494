<template>
  <div>
    <div v-if="timeShift">
      <div
        v-for="(timeShift, timeShiftIndex) in timeShift"
        v-bind:key="timeShiftIndex"
        class="
          border border-solid border-gray-200 border-opacity-0
          mb-2
          -mx-2
          p-2
        "
        :class="{ 'border-opacity-100': timeShift.length > 1 }"
      >
        <div class="flex flex-row mb-4">
          <div class="flex-1 flex align-center h-9">Days of the week</div>
          <v-btn icon v-on:click="removeTimeShiftAtIndex(timeShiftIndex)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>

        <div class="flex monospaced">
          <v-item-group
            multiple
            v-on:change="
              (daysOfTheWeek) => setValue(timeShiftIndex, 'days', daysOfTheWeek)
            "
            class="space-x-1"
            :value="timeShift.days"
          >
            <v-item
              v-for="(dayOfTheWeek, daysOfTheWeekIndex) in daysOfTheWeek"
              :key="daysOfTheWeekIndex"
              :value="dayOfTheWeek.day"
              v-slot="{ active, toggle }"
            >
              <v-btn
                text
                :ripple="false"
                class="p-0 min-w-0 h-auto cursor-pointer"
                @click="toggle"
              >
                <v-chip
                  class="day-selector-chip cursor-pointer"
                  active-class="bg-green-light text-light"
                  :input-value="active"
                >
                  {{ dayOfTheWeek.abbreviated }}
                </v-chip>
              </v-btn>
            </v-item>
          </v-item-group>
        </div>
        <div class="flex mt-4">
          <div class="flex-1 mt-1">
            <div class="time-selector-title">Start</div>
            <TimeSelector v-model="timeShift.start" />
          </div>

          <div class="flex-1 mt-1">
            <div class="time-selector-title">End</div>
            <TimeSelector v-model="timeShift.end" />
          </div>
        </div>
      </div>
    </div>
    <v-btn
      class="border-green-mid text-green-mid"
      outlined
      v-on:click="addNewTimeShift"
      ><v-icon class="mr-2">mdi-plus</v-icon> Add
      {{ timeShift && timeShift.length ? "another " : "" }}time shift</v-btn
    >
  </div>
</template>
<script>
import TimeSelector from "./TimeSelector";

export default {
  name: "TimeShiftSelector",
  props: {
    defaultValues: Array,
    value: {
      type: Array,
      default: function () {
        return [this.defaultValues];
      },
    },
  },
  components: {
    TimeSelector,
  },
  data: function () {
    return {
      timeShift: this.value,
      daysOfTheWeek: [
        { day: "Monday", abbreviated: "M" },
        { day: "Tuesday", abbreviated: "T" },
        { day: "Wednesday", abbreviated: "W" },
        { day: "Thursday", abbreviated: "T" },
        { day: "Friday", abbreviated: "F" },
        { day: "Saturday", abbreviated: "S" },
        { day: "Sunday", abbreviated: "S" },
      ],
    };
  },
  watch: {
    timeShift: {
      handler: function (newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
  methods: {
    setValue(index, type, data) {
      const dayList = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      const sortedData = data.sort((a, b) => {
        const value = dayList.indexOf(a) - dayList.indexOf(b);
        return value;
      });

      this.timeShift[index][type] = sortedData;
      this.$emit("input", this.value);
    },
    getDayValue(day) {
      const dayValue = this.value.find((timeShift) => timeShift.day === day);
      return dayValue;
    },
    addNewTimeShift() {
      if (!this.timeShift) {
        this.timeShift = [];
      }

      const defaultTimeShiftClone = JSON.parse(JSON.stringify(this.defaultValues[0]));

      this.timeShift.push(defaultTimeShiftClone);
    },
    removeTimeShiftAtIndex(index) {
      this.timeShift.splice(index, 1);
    },
  },
};
</script>