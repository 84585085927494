<template>
  <div>
    <v-data-table
      :headers="userSquadSprintHeaders"
      :items="items"
      :footer-props="userSquadSprintsfooterOptions"
      :disable-sort="true"
      item-key="_id"
      no-data-text="No sprints found"
    >
      <template v-slot:[`item.sprint`]="{ item }">
        <div>
          {{ format(new Date(item.sprint.startDate), "do MMM") }}
          -
          {{ format(new Date(item.sprint.endDate), "do MMM") }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { format } from "date-fns";

export default {
  name: "UserSquadSprintTable",
  props: {
    items: Array,
  },
  data() {
    return {
      userSquadSprintHeaders: [
        {
          text: "Sprint",
          value: "sprint",
        },
        {
          text: "Capacity",
          value: "capacity",
          // width: "45%",
        },
      ],
      userSquadSprintsfooterOptions: {
        "items-per-page-options": [5],
      },
    };
  },
  methods: {
    format,
  },
};
</script>