import axios from "axios"
import pluralize from 'pluralize'
import modelQueries from "./model_queries";

axios.interceptors.response.use(function (response) {
  if (response.data.error) {
    return Promise.reject(response.data.error);
  } else {
    return Promise.resolve(response)
  }
}, function (error) {
  return Promise.reject(error);
});

import { capitalise, getObjectAttributes } from './utils'

function addToString(string, value) {
  if (string.length > 0) {
    string += ','
  }

  string += value

  return string;
}


function parseAttributes(attributes) {
  let parsedAttributes = '';
  Object.keys(attributes).forEach((attribute) => {

    if (attribute === 'base') {
      parsedAttributes = addToString(parsedAttributes, attributes[attribute])
    } else {
      const complexAttributes = `${attribute} {
          ${parseAttributes(attributes[attribute])}
        }`

      parsedAttributes = addToString(parsedAttributes, complexAttributes)
    }
  })

  return parsedAttributes;
}

function getAttributes(modelType, attributeType) {
  let attributes;
  if (modelType.endsWith('Schema') && attributeType === 'schema') {
    attributes = { base: ['schema', 'fields'] }
  } else {
    attributes = modelQueries[modelType][attributeType]
  }

  return attributes;
}

export function query(isPublic, modelType, attributeType, id, options) {
  const attributes = getAttributes(capitalise(pluralize.singular(modelType)), attributeType)
  const parsedAttributes = parseAttributes(attributes)

  let query = ''
  let parameters = ''
  let variables = {}
  if (id) {
    query += '$_id: String!'
    parameters += '_id: $_id'
    variables._id = id
  }

  if (options) {
    Object.keys(options).forEach((optionKey) => {
      if (query.length) query += ', '
      if (parameters.length) parameters += ', '

      let type = ''
      switch (typeof options[optionKey]) {
        case "string":
          type = `String`
          break;
        case "number":
          type = `Float`
          break;
        case "boolean":
          type = `Boolean`
          break;
      }

      query += `$${optionKey}: ${type}`
      parameters += optionKey + ': $' + optionKey
      variables[optionKey] = options[optionKey]
    });
  }

  if (query.length) {
    query = '(' + query + ')'
  }
  if (parameters.length) {
    parameters = '(' + parameters + ')'
  }



  // query ${id ? `($_id: String!)` : ''} {

  return axios.post(`${isPublic ? '/public' : ''}/graphql`, {
    query: `
      query ${query} {
        get${capitalise(modelType)} ${parameters} {
          ${parsedAttributes}
        }
      }
    `,
    variables

  }).then((response) => {
    if (response.data.errors) throw response.data.errors
    return response.data.data[`get${capitalise(modelType)}`]
  })
}

export function get(modelType, attributeType, id, options) {
  return query(false, modelType, attributeType, id, options);
}

export function softDelete(modelType, ids) {
  return deleteGraphql(modelType, ids);
}

export function deleteGraphql(modelType, _ids) {
  const data = {
    query: `
      mutation ($_ids: [String!]) {
        delete${capitalise(pluralize.plural(modelType))} (_ids: $_ids) {
          _id
        }
      }
    `,
    variables: { _ids }
  }

  return axios.post(`/graphql`, data).then((response) => {
    if (response.data.errors) throw response.data.errors
    return response.data.data[`delete${capitalise(modelType)}`]
  })
}

export function mutate(mutationName, _ids, options) {

  let query = '$_ids: [String!]'
  let parameters = '_ids: $_ids'
  let variables = { _ids }

  if (options) {
    Object.keys(options).forEach((optionKey) => {
      if (query.length) query += ', '
      if (parameters.length) parameters += ', '

      let type = ''
      switch (typeof options[optionKey]) {
        case "string":
          type = `String`
          break;
        case "number":
          type = `Float`
          break;
        case "boolean":
          type = `Boolean`
          break;
      }

      query += `$${optionKey}: ${type}`
      parameters += optionKey + ': $' + optionKey
      variables[optionKey] = options[optionKey]
    });
  }

  // e.g.
  // ${mutationType}${capitalise(pluralize.plural(modelType))} (${parameters}) {
  // would be
  // end


  const data = {
    query: `
      mutation (${query}) {
        ${mutationName} (${parameters}) {
          _id
        }
      }
    `,
    variables
  }

  return axios.post(`/graphql`, data).then((response) => {
    if (response.data.errors) throw response.data.errors
    return response.data.data[mutationName]
  })
}


export function search(modelType, query, queryType, cancelToken) {
  const attributes = getAttributes(capitalise(pluralize.singular(modelType)), 'search')
  const parsedAttributes = parseAttributes(attributes)
  return axios({
    method: 'POST',
    url: `/graphql`, data: {
      query: `
      query ($query: String!, $queryType: String!) {
        search${capitalise(pluralize.plural(modelType))} (query: $query, queryType: $queryType) {
          ${parsedAttributes}
        }
      }
    `,
      variables: {
        query,
        queryType
      },
    },
    cancelToken
  }).then((response) => {
    if (response.data.errors) throw response.data.errors
    return response.data.data[`search${pluralize.plural(capitalise(modelType))}`]
  })
}

function convertFieldsQueryVariables(fields) {
  return Object.keys(fields).map(field => `$${field}: ${fields[field]}`).join(',')
}

function convertFieldsToQueryParameters(fields) {
  return Object.keys(fields).map(field => `${field}: $${field}`).join(',')
}

function getUploads(object, fields) {
  const uploads = [];
  Object.keys(fields).forEach((field) => {
    if (fields[field].includes('Upload')) {
      if (object[field] !== null) {
        uploads.push(field)
      }
    }
  })

  return uploads;
}

function parseObject(object, fields) {
  const parsedObject = Object.assign({}, object)

  Object.keys(object).forEach((attribute) => {
    if (attribute.endsWith('Url')) {
      const uploadAttribute = attribute.slice(0, -3)
      if (fields[uploadAttribute] && fields[uploadAttribute] === "Upload") {
        parsedObject[uploadAttribute] = object[attribute]
        delete parsedObject[attribute]
      }
    }
  })

  return parsedObject;
}

export function saveData(isPublic, modelType, object, fields) {
  const variables = convertFieldsQueryVariables(fields)
  const parameters = convertFieldsToQueryParameters(fields)

  const saveAttributes = getAttributes(capitalise(pluralize.singular(modelType)), 'save')
  const attributes = saveAttributes || getAttributes(capitalise(pluralize.singular(modelType)), 'full')
  const parsedAttributes = parseAttributes(attributes)

  const parsedObject = parseObject(object, fields)
  const objectToSend = getObjectAttributes(parsedObject, fields, attributes)

  const map = {}

  const operation = {
    query: `
      mutation(${variables}) {
        save${capitalise(modelType)}(${parameters}) {
          ${parsedAttributes}
        }
      }
    `,
  }

  const uploads = getUploads(parsedObject, fields)
  if (uploads.length > 0) {
    uploads.forEach((uploadAttribute, index) => {
      objectToSend[uploadAttribute] = null;

      map[index] = [`variables.${uploadAttribute}`]
    })
  }

  operation.variables = objectToSend;

  const body = new FormData();
  body.append('operations', JSON.stringify(operation));
  body.append('map', JSON.stringify(map));

  Object.keys(map).forEach((mapIndexString) => {
    const mapIndex = parseInt(mapIndexString)
    const attribute = map[mapIndex][0].replace('variables.', '')
    body.append(mapIndex, parsedObject[attribute]);
  })

  return axios.post(`${isPublic ? '/public' : ''}/graphql`, body).then((response) => {
    if (response.data.errors) throw response.data.errors
    return response.data.data[`save${capitalise(modelType)}`]
  })
}

export function save(modelType, object, fields) {
  return saveData(false, modelType, object, fields);
}

function handleErrorOrErrors(error, component) {
  if (error.length) {
    error.forEach(errorSingle => handleErrorOrErrors(errorSingle, component))
  } else {
    if (['auth/argument-error', 'auth/session-cookie-revoked'].includes(error.code)) {
      component.$emit("alert", { type: "error", message: "Not logged in" });
      component.$router.push('/login')
    } else if (error.message) {
      component.$emit("alert", { type: "error", message: error.message });
    } else {
      console.error(error)
    }
  }
}

export const handleError = handleErrorOrErrors