import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

import DefaultLayout from '../layouts/DefaultLayout'
import AuthenticatedLayout from '../layouts/AuthenticatedLayout'
import AccountLayout from '../layouts/AccountLayout'
// import ThemedLayout from '../layouts/ThemedLayout'

import LoginPage from '../pages/Login/LoginPage'

import DashboardPage from '../pages/Dashboard/DashboardPage'
import SquadsPage from '../pages/Squads/SquadsPage'
import SquadPage from '../pages/Squad/SquadPage'
import UsersPage from '../pages/Users/UsersPage'
import UserPage from '../pages/User/UserPage'
import ProfilePage from '../pages/Profile/ProfilePage'
import CompanyPage from '../pages/Company/CompanyPage'
import UserManagementPage from '../pages/UserManagement/UserManagementPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      // default
      {
        path: "",
        redirect: "login"
      },
      {
        path: 'login',
        component: LoginPage
      }
    ]
  },
  {
    path: '/',
    component: AuthenticatedLayout,
    children: [
      // high level
      {
        path: 'dashboard',
        component: DashboardPage,
      },
      {
        path: 'squads',
        component: SquadsPage,
      },
      {
        path: 'users',
        component: UsersPage,
      },
      // detail
      {
        path: 'users/:id',
        component: UserPage,
      },
      {
        path: 'squads/:id',
        component: SquadPage,
      },
      {
        path: 'accounts/:id',
        component: AccountLayout,
        children: [
          {
            path: '',
            redirect: 'profile'
          },
          {
            path: 'profile',
            component: ProfilePage,
          },
          {
            path: 'company',
            component: CompanyPage,
          },
          {
            path: 'users',
            component: UserManagementPage,
          },
        ],
      },
    ]
  },
  // {
  //   path: '/sign-up',
  //   component: ThemedLayout,
  //   children: [
  //     // first step
  //     {
  //       path: '/',
  //       redirect: 'account-type'
  //     },
  //     {
  //       path: 'account-type',
  //       component: AccountTypePage
  //     },
  //     {
  //       path: 'company-setup',
  //       component: CompanySetupPage
  //     },
  //   ]
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
