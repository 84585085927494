<template>
  <div style="position: relative; height: 100%; width: 100%">
    <canvas
      style="position: absolute; top: 0; bottom: 0; right: 0; left: 0"
      ref="chart"
    />
  </div>
</template>

<script>
import {
  Chart,
  // ArcElement,
  LineElement,
  // BarElement,
  PointElement,
  // BarController,
  // BubbleController,
  // DoughnutController,
  LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  // RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  // Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(
  // ArcElement,
  LineElement,
  // BarElement,
  PointElement,
  // BarController,
  // BubbleController,
  // DoughnutController,
  LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  // RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  // Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

export default {
  name: "CapacityHistoryChart",
  props: {
    type: String,
    labels: Array,
    datasets: Array,
    options: Object,
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    labels(newLabels) {
      this.chart.data.labels = newLabels;
      this.chart.update();
    },
    datasets(newDatasets) {
      this.chart.data.datasets = newDatasets;
      this.chart.update();
    },
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.chart = new Chart(this.$refs.chart, {
        type: "line",
        data: {
          labels: this.labels,
          // ["Jan", "Feb", "Mar"],
          datasets: this.datasets,
          // [
          //   {
          //     label: this.title,
          //     data: [65, 59, 80],
          //     fill: false,
          //     borderColor: "rgb(75, 192, 192)",
          //     tension: 0.1,
          //   },
          //   {
          //     label: this.title,
          //     data: [35, 29, 50],
          //     fill: false,
          //     borderColor: "rgb(35, 132, 132)",
          //     tension: 0.1,
          //   }
          // ],
        },
        // data: this.data,
        options: this.options,
      });
    },
  },
  // beforeDestroy() {
  //   window.removeEventListener("resize", this.renderChartComponent);
  // },
};

// DATASET EXAMPLE
// [
//   {
//     label: "Current Quarter",
//     data: [
//       { x: new Date(2021, 4, 29), y: 3 },
//       { x: new Date(2021, 4, 28), y: 4 },
//       { x: new Date(2021, 4, 27), y: 1 },
//       { x: new Date(2021, 4, 26), y: 6 },
//       { x: new Date(2021, 4, 25), y: 8 },
//       { x: new Date(2021, 4, 24), y: 5 },
//       { x: new Date(2021, 4, 23), y: 3 },
//       { x: new Date(2021, 4, 22), y: 8 },
//       { x: new Date(2021, 4, 21), y: 9 },
//       // { x: new Date(2021, 3), y: 8 },
//       // { x: new Date(2021, 2), y: 5 },
//     ],
//     borderColor: 'green',
//     backgroundColor: "rgba(0,0,0,0)",
//   },
//   {
//     label: "Previous Quarter",
//     data: [
//       { x: new Date(2021, 4, 29), y: 2 },
//       { x: new Date(2021, 4, 28), y: 3 },
//       { x: new Date(2021, 4, 27), y: 1 },
//       { x: new Date(2021, 4, 26), y: 4 },
//       { x: new Date(2021, 4, 25), y: 7 },
//       { x: new Date(2021, 4, 24), y: 4 },
//       { x: new Date(2021, 4, 23), y: 2 },
//       { x: new Date(2021, 4, 22), y: 9 },
//       { x: new Date(2021, 4, 21), y: 8 },
//       // { x: new Date(2021, 3), y: 8 },
//       // { x: new Date(2021, 2), y: 5 },
//     ],
//     borderColor: 'lightgreen',
//     backgroundColor: "rgba(0,0,0,0)",
//     borderDash: [10],
//     // backgroundColor: "#42c4b6",
//   },
// ],
</script>
