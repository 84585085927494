var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-4",attrs:{"id":"users-page"}},[_c('v-card',[_c('div',{staticClass:"p-4"},[_c('div',[_c('span',{staticClass:"text-2xl text-dark font-bold"},[_vm._v("Users")])]),_c('div',{staticClass:"flex mt-4"},[_c('v-text-field',{staticClass:"p-0 m-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-spacer'),_c('div',{staticClass:"flex flex-row-reverse"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bg-green-light",attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(" create new "),_c('v-divider',{staticClass:"ml-4 mr-3 border-white",attrs:{"vertical":""}}),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{staticClass:"p-0"},[_c('v-list-item',{staticClass:"p-0"},[_c('v-btn',{staticClass:"p-0 w-full",attrs:{"text":""},on:{"click":function($event){_vm.showCreateNewSingle = true}}},[_vm._v(" Single user ")])],1),_c('v-list-item',{staticClass:"p-0"},[_c('v-btn',{staticClass:"p-0 w-full",attrs:{"text":""},on:{"click":function($event){_vm.showCreateNewBulk = true}}},[_vm._v(" Bulk import ")])],1)],1)],1),_c('ModelEdit',{attrs:{"type":"User","title":"Create a new user"},on:{"saved":function () { return _vm.getUsers(); },"alert":function (alert) { return _vm.$emit('alert', alert); }},model:{value:(_vm.showCreateNewSingle),callback:function ($$v) {_vm.showCreateNewSingle=$$v},expression:"showCreateNewSingle"}}),(_vm.selectedUsers.length)?_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","color":"red"},on:{"click":_vm.endEmploymentForUsers}},[_vm._v(" End employment"+_vm._s(_vm.selectedUsers.length > 1 ? "s" : null)+" ")]):_vm._e()],1)],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalCount,"footer-props":_vm.footerOptions,"loading":_vm.loading,"disable-sort":true,"show-select":"","item-key":"_id","no-data-text":"No users found"},on:{"update:page":_vm.onUserPageUpdate,"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"36px","color":"teal"}},[(item.profileImageUrl)?_c('v-img',{attrs:{"alt":"Avatar","src":item.profileImageUrl}}):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.initials))])],1),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.fullName))])]}},{key:"item.squads",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSquadsList(item)))])]}}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }