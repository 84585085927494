var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticClass:"bg-dark flex flex-col",attrs:{"dark":"","permanent":"","stateless":"","app":"","mini-variant-width":"70","mini-variant":_vm.useMiniNavigationDrawer}},[_c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('img',{staticClass:"w-11/12 p-3 m-auto",attrs:{"src":require("@/assets/images/logo.svg"),"alt":"trackk logo"}}),_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.items),function(item){return _c('v-tooltip',{key:item.title,attrs:{"right":"","disabled":!_vm.useMiniNavigationDrawer},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":item.path}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1),_c('div',{class:item.path === _vm.$route.path
                    ? 'selected bg-green-light'
                    : 'not-selected'})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])}),1),_c('v-spacer'),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.useMiniNavigationDrawer},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"p-0 m-0",attrs:{"link":"","to":("/accounts/" + (_vm.user._id))}},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"p-2"},[_c('v-avatar',{staticClass:"text-6xl",attrs:{"size":"38px","color":"teal"}},[(_vm.user.profileImageUrl)?_c('v-img',{attrs:{"alt":"Profile picture","src":_vm.user.profileImageUrl}}):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.user.initials))])],1)],1)],1)]}}])},[_c('span',[_vm._v("Your account")])])],1)],1)],1),_c('v-main',{staticClass:"h-full bg-light"},[(!_vm.layoutLoading)?_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"loading":_vm.loading,"user":_vm.user,"currentUser":_vm.user},on:{"alert":function (alert) { return _vm.$emit('alert', alert); },"loading":_vm.setLoading}}):_vm._e(),(_vm.loading || _vm.layoutLoading)?_c('v-progress-circular',{staticClass:"flex mx-auto mt-4",attrs:{"size":50,"indeterminate":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }