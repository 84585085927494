import axios from "axios"

export function requestSignInWithEmailLink(email) {
  return axios.get(`/auth/sign_in/request_passwordless?email=${email}`).then(response => response.data)
}

export function signInWithEmailLink(email) {
  return axios.get(`/auth/sign_in/passwordless?email=${email}&request=${encodeURIComponent(window.location.href)}`).then(response => response.data)
}

export function signInWithGoogle(accessToken) {
  return axios.get(`/auth/sign_in/google?access_token=${encodeURIComponent(accessToken)}`).then(response => response.data)
}

export function signInWithGoogleMicrosoft(idToken, accessToken) {
  return axios.get(`/auth/sign_in/microsoft?id_token=${encodeURIComponent(idToken)}&access_token=${encodeURIComponent(accessToken)}`).then(response => response.data)
}

export function signOut() {
  return axios.get('/auth/sign_out')
}

export function checkUser() {
  return axios.get('/auth/check_user')
}

export function companyLogin(companyId) {
  return axios.post('/auth/company_login', { companyId })
}

export function inviteCheck(email, hash) {
  return axios.get(`/sign_up/invite_check?email=${email}&u=${hash}`)
}