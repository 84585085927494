export default {
  User: {
    save: {
      // base: ['_id', 'profileImageUrl', 'firstName', 'lastName', 'email', 'jobTitle', 'employmentType', 'costCurrency', 'cost', 'costType'],
      base: ['_id', 'profileImageUrl', 'firstName', 'lastName', 'email', 'jobTitle'],
      skills: {
        base: ['_id']
      },
      timeShifts: {
        base: ['days'],
        start: {
          base: ['hour', 'minute', 'meridiem']
        },
        end: {
          base: ['hour', 'minute', 'meridiem']
        }
      }
    },
    overview: {
      base: ['totalCount'],
      data: {
        base: ['_id', 'fullName', 'initials', 'email', 'profileImageUrl', 'jobTitle', 'isEmployed'],
        squads: { base: ['name'] }
      },
    },
    full: {
      // base: ['_id', 'deleted', 'firstName', 'lastName', 'initials', 'email', 'profileImageUrl', 'jobTitle', 'employmentType', 'cost', 'costType', 'costCurrency', 'score', 'isEmployed', 'workHoursPerWeek'],
      base: ['_id', 'deleted', 'firstName', 'lastName', 'initials', 'email', 'profileImageUrl', 'jobTitle', 'employmentType', 'score', 'isEmployed', 'workHoursPerWeek'],
      skills: {
        base: ['_id', 'name']
      },
      timeShifts: {
        base: ['days'],
        start: { base: ['hour', 'minute', 'meridiem'] },
        end: { base: ['hour', 'minute', 'meridiem'] },
      },
      timeOff: {
        base: ['_id', 'startDate', 'endDate']
      },
      capacity: {
        total: { base: ['day', 'week', 'month', 'quarter'] },
        remaining: { base: ['day', 'week', 'month', 'quarter'] },
      },
      squads: {
        base: ['_id', 'name']
      },
      currentEmployment: {
        base: ['startDateMilliseconds', 'endDateMilliseconds']
      }
    },
  },
  Company: {
    overview: {
      base: ['_id', 'name'],
      capacity: {
        total: { base: ['day', 'week', 'month', 'quarter'] },
        remaining: { base: ['day', 'week', 'month', 'quarter'] },
        previous: { base: ['day', 'week', 'month', 'quarter'] },
      }
    },
    full: {
      base: ['_id', 'name'],
      capacity: {
        total: { base: ['day', 'week', 'month', 'quarter'] },
        remaining: { base: ['day', 'week', 'month', 'quarter'] },
      }
    },
  },
  SignUp: {
    full: {
      base: ['companyName', 'companyId', 'firstName', 'lastName', 'email', 'profileImageUrl', 'jobTitle'],
      timeShifts: {
        base: ['days'],
        start: { base: ['hour', 'minute', 'meridiem'] },
        end: { base: ['hour', 'minute', 'meridiem'] },
      },
    },
  },
  Squad: {
    save: {
      base: ['_id', 'name'],
      users: {
        base: ['_id'],
      },
      sprint: { base: ['duration', 'startDateMilliseconds'] }
    },
    overview: {
      base: ['totalCount'],
      data: {
        base: ['_id', 'name'],
        capacity: {
          total: { base: ['sprint'] },
          remaining: { base: ['sprint'] },
          previous: { base: ['sprint'] },
        },
        users: {
          base: ['_id', 'profileImageUrl', 'firstName', 'lastName', 'jobTitle'],
          capacity: {
            remaining: { base: ['day', 'week'] },
          }
        },
      },
    },
    full: {
      base: ['_id', 'name'],
      users: {
        base: ['_id', 'firstName', 'lastName', 'fullName', 'profileImageUrl', 'jobTitle', 'isEmployed'],
        capacity: {
          total: { base: ['day', 'week', 'month', 'quarter'] },
          remaining: { base: ['day', 'week', 'month', 'quarter'] },
        },
        skills: {
          base: ['name'],
        },
        squads: {
          base: ['name']
        }
      },
      capacity: {
        total: { base: ['day', 'week', 'month', 'quarter'] },
        remaining: { base: ['day', 'week', 'month', 'quarter'] },
      },
      sprint: { base: ['duration', 'startDateMilliseconds'] }
    },
  },
  CompanyReport: {
    full: {
      totalCapacity: {
        base: ['dateMilliseconds', 'value']
      },
      // actualCapacity: {
      //   base: ['dateMilliseconds', 'value']
      // },
      // cost: {
      //   base: ['dateMilliseconds', 'value']
      // }
    }
  },
  UserReport: {
    full: {
      totalCapacity: {
        base: ['dateMilliseconds', 'value']
      },
      // actualCapacity: {
      //   base: ['dateMilliseconds', 'value']
      // },
      // cost: {
      //   base: ['dateMilliseconds', 'value']
      // }
    }
  },
  SquadReport: {
    full: {
      totalCapacity: {
        base: ['dateMilliseconds', 'value']
      },
      // actualCapacity: {
      //   base: ['dateMilliseconds', 'value']
      // },
      // cost: {
      //   base: ['dateMilliseconds', 'value']
      // }
    }
  },
  UserSquadSprint: {
    full: {
      base: ['squadCapacity', 'userCapacity'],
      sprint: {
        base: ['startDate', 'endDate']
      }
    }
  },
  SquadSprint: {
    full: {
      base: ['capacity'],
      sprint: {
        base: ['startDate', 'endDate']
      }
    }
  },
  Sprint: {
    overview: {
      base: ['totalCount'],
      data: {
        base: ['_id', 'squadId', 'name', 'startDateMilliseconds', 'endDateMilliseconds'],
      }
    },
    full: {
      base: [
        '_id',
        'squadId',
        'startDateMilliseconds',
        'endDateMilliseconds',
      ],
      actualCapacity: {
        base: [
          'title',
          'value'
        ]
      },
      capacity: {
        total: { base: ['sprint'] },
        remaining: { base: ['sprint'] },
      },
      items: {
        base: ['link', 'title', 'itemType', 'userId', 'estimate']
      }
    }
  },
  NewSprint: {
    overview: {
      base: [
        '_id',
        'squadId',
        'startDateMilliseconds',
        'endDateMilliseconds',
      ],
      actualCapacity: {
        base: [
          'title',
          'value'
        ]
      }
    },
    full: {
      base: [
        '_id',
        'squadId',
        'startDateMilliseconds',
        'endDateMilliseconds',
      ],
      actualCapacity: {
        base: [
          'title',
          'value'
        ]
      }
    }
  },
  TimeOff: {
    full: {
      base: [
        '_id',
        'userId',
        'startDate',
        'endDate',
      ]
    }
  },
  UserInvite: {
    full: {
      base: [ 'emails' ]
    },
    // save: {
    //   base: [ 'emails' ]
    // }
  }
}