<template>
  <div>
    <div
      class="flex"
      :class="{ 'mt-2': key > 0 }"
      :key="key"
      v-for="(inviteItem, key) in invites"
    >
      <!-- :value="invites[key].email" -->
        <!-- v-on:input="(value) => updateItem(key, 'email', value)" -->
      <v-text-field
        v-model="invites[key].email"
        outlined
        class="flex-1"
        label="email"
        placeholder="name@trackk.io"
        :required="true"
        :rules="rules"
        hide-details="auto"
      />
      <v-combobox
        v-model="invites[key].permissions"
        placeholder="Permissions"
        class="text-left ml-2 flex-1"
        :items="allowedPermissions"
        item-text="label"
        item-value="value"
        hide-details="auto"
        multiple
        outlined
      >
        <template v-slot:selection="{ item }">
          <div class="px-4 py-2 mr-2 bg-green-light bg-opacity-40 text-dark">
            {{ item.label }}
          </div>
        </template>
      </v-combobox>

      <v-btn icon class="m-auto ml-2" @click="invites.splice(key, 1)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>

    <v-btn outlined class="mt-4 border-green-mid text-green-mid" @click="addEmptyInvite">
      <v-icon class="mr-2">mdi-plus</v-icon> Add another user
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "UserInvites",
  data: function () {
    const defaultRules = [(value) => !!value || "Required"];

    const emptyInvite = { email: "", permissions: [] };

    return {
      emptyInvite,
      invites: [Object.assign({}, emptyInvite)],
      rules: defaultRules,
      allowedPermissions: [
        {
          label: "Manage users",
          value: "manage_users",
        },
        {
          label: "Manage squads",
          value: "manage_squads",
        },
      ],
    };
  },
  methods: {
    addEmptyInvite() {
      this.invites.push(Object.assign({}, this.emptyInvite));
    },
    updateItem(key, field, value) {
      this.invites[key][field] = value;
    },
  },
};
</script>