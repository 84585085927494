<template>
  <v-card
    v-ripple
    :loading="loading"
    :disabled="loading"
    @click="$emit('click')"
    class="cursor-pointer"
  >
    <div class="flex align-center p-4">
      <img
        height="24px"
        width="24px"
        src="@/assets/images/sign_in_hotmail.svg"
        alt="sign in with hotmail"
      />

      <div class="ml-4">
        <slot><span>Sign in with Microsoft</span></slot>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "AuthWithMicrosoftButton",
  props: {
    loading: Boolean,
  },
};
</script>