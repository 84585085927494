<template>
  <div>
    <div :key="index" v-for="(item, index) in items" class="email-input-list">
      <div class="flex">
        <v-text-field
          class="flex-1"
          :value="item"
          v-on:input="(value) => updateItem(index, value)"
          outlined
          :label="label"
          :placeholder="placeholder"
          hide-details="auto"
          append-icon="mdi-delete"
          @click:append="() => deleteItem(index)"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TextArray",
  props: ["value", "label", "placeholder"],
  data() {
    return {
      items: this.value || [""],
    };
  },
  watch: {
    items: {
      handler(newValue) {
        // let valueToUse = [...newValue];
        // const lastItemIndex = valueToUse.length - 1;
        // console.log('lastItemIndex', lastItemIndex)
        // if (lastItemIndex != 0 && valueToUse[lastItemIndex].length == 0) {
        //   console.log('popping last item')
        //   valueToUse.pop();
        // }
        // this.$emit("input", valueToUse);


        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.items = newValue;
      },
      deep: true,
    },
  },
  methods: {
    updateItem(index, value) {
      const oldValue = this.items[index];
      this.items[index] = value;

      if (value != oldValue && this.items.length == index + 1) {
        this.items.push("");
      }

      // watcher should trigger emit input
    },
    deleteItem(index) {
      if (this.items.length != index + 1) {
        this.items.splice(index, 1);
      }
    },
  },
};
</script>
<style>
.email-input-list:not(:first-child) {
  margin-top: 14px;
}
</style>