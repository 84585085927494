<template>
  <div>
    <v-data-table
      :headers="userSquadSprintHeaders"
      :items="items"
      :footer-props="userSquadSprintsfooterOptions"
      :disable-sort="true"
      item-key="_id"
      no-data-text="No sprints found"
    >
      <template v-slot:[`item.sprint`]="{ item }">
        <div>
          {{ format(new Date(item.sprint.startDate), "do MMM") }}
          -
          {{ format(new Date(item.sprint.endDate), "do MMM") }}
        </div>
      </template>
      <template v-slot:[`item.userCapacity`]="{ item }">
        <div>
          {{ item.userCapacity }}
          <span class="text-xs">({{ calculateUserContribution(item) }})</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { format } from "date-fns";

export default {
  name: "UserSquadSprintTable",
  props: {
    items: Array,
  },
  data() {
    return {
      userSquadSprintHeaders: [
        {
          text: "Sprint",
          value: "sprint",
        },
        {
          text: "Squad Capacity",
          value: "squadCapacity",
          width: "20%",
        },
        {
          text: "Your Capacity",
          value: "userCapacity",
          width: "25%",
        },
      ],
      userSquadSprintsfooterOptions: {
        "items-per-page-options": [5],
      },
    };
  },
  methods: {
    format,
    calculateUserContribution(userSquadSprintItem) {
      if (
        userSquadSprintItem.userCapacity &&
        userSquadSprintItem.squadCapacity
      ) {
        const value = Math.round(
          (userSquadSprintItem.userCapacity /
            userSquadSprintItem.squadCapacity) *
            100
        );
        return `${value}%`;
      }

      return "0%";
    },
  },
};
</script>