<template>
  <div id="squads-page" class="p-4">
    <v-card>
      <div class="p-4">
        <div><span class="text-2xl text-dark font-bold">Squads</span></div>
        <div class="flex mt-4">
          <v-text-field
            class="p-0 m-0"
            v-model="searchTerm"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer />
          <div class="flex flex-row-reverse">
            <ModelEdit
              type="Squad"
              title="Create a new squad"
              v-on:saved="squadSaved"
              v-on:alert="(alert) => $emit('alert', alert)"
              width="900"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark class="bg-green-light">Create new</v-btn>
              </template>
            </ModelEdit>
          </div>
        </div>
      </div>
      <v-data-table
        v-model="selectedSquads"
        :headers="headers"
        :items="squads"
        :items-per-page="itemsPerPage"
        :server-items-length="totalCount"
        :footer-props="footerOptions"
        :loading="loading"
        :disable-sort="true"
        show-select
        @update:page="onSquadPageUpdate"
        @click:row="onRowClick"
        item-key="_id"
      >
        <template v-slot:[`item.sprintCapacity`]="{ item }">
          <span class="ml-2"
            >{{ item.capacity.remaining.sprint }} /
            {{ item.capacity.total.sprint }}</span
          >
        </template>
        <template v-slot:[`item.previousSprintCapacity`]="{ item }">
          <span class="ml-2">{{ item.capacity.previous.sprint }} </span>
        </template>
        <template v-slot:[`item.members`]="{ item }">
          <span class="ml-2">{{ item.users.length }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { get, handleError } from "../../services/database";

import ModelEdit from "../../components/ModelEdit.vue";

export default {
  name: "SquadsPage",
  components: {
    ModelEdit,
  },
  data: () => ({
    loading: false,
    squads: [],
    selectedSquads: [],
    searchTerm: "",
    searchTimeout: null,
    currentPage: 0,
    totalCount: 0,
    itemsPerPage: 10,
    footerOptions: {
      "items-per-page-options": [10],
    },
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Current sprint's remaning capacity",
        value: "sprintCapacity",
      },
      {
        text: "Previous sprint capacity",
        value: "previousSprintCapacity",
      },
      {
        text: "Members",
        value: "members",
      },
    ],
  }),
  watch: {
    searchTerm(newValue) {
      this.loading = true;
      this.selectedSquads = [];
      if (!newValue.length) {
        this.getSquads(0);
      } else {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.getSquads(0), 1000);
      }
    },
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
    },
    update() {
      this.getSquads();
    },
    onSquadPageUpdate(page) {
      const dbPage = page - 1;

      this.currentPage = dbPage;
      this.getSquads(dbPage);
    },
    squadSaved() {
      this.getSquads(this.currentPage);
    },
    getSquads(page = 0) {
      this.loading = true;
      return get("Squads", "overview", null, {
        itemsPerPage: this.itemsPerPage,
        page,
        searchTerm: this.searchTerm,
      })
        .then(({ data, totalCount }) => {
          this.squads = data;
          this.totalCount = totalCount;
        })
        .catch((error) => {
          handleError(error, this);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClick(item) {
      this.$router.push(`/squads/${item._id}`);
    },
  },
  mounted() {
    this.getSquads();
  },
};
</script>
<style>
#squads-page tbody tr {
  cursor: pointer;
}
</style>