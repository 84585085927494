<template>
  <div id="date-picker">
    <div class="text-lg">{{ label }}</div>
    <v-date-picker
      ref="calendar"
      :value="date"
      @input="dateSelected"
      elevation="3"
      :width="width"
      color="#42c4b6"
      header-color="#42c4b6"
    />
  </div>
</template>
<script>
import { format } from "date-fns";

export default {
  props: {
    label: String,
    width: String,
    value: Number,
  },
  data() {
    return {
      date: this.value
        ? format(new Date(this.value), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
    };
  },
  methods: {
    dateSelected(newDate) {
      this.date = newDate;
      this.$emit("input", new Date(newDate).getTime());
    },
  },
};
</script>
<style>
#date-picker .v-picker .v-date-picker-table td button {
  border-radius: 20px;
}
</style>