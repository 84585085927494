import { OAuthProvider, GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { initializeApp } from 'firebase/app';

import { signInWithGoogle as signInWithGoogleAPI, signInWithGoogleMicrosoft as signInWithGoogleMicrosoftAPI } from '../services/api'

const firebaseConfig = {
  apiKey: "AIzaSyDLNA2xi2qR_3bjUNXX3sHN6giHMcHObjQ",
  authDomain: "app.trackk.io",
  projectId: "trackk-2b0c7",
  storageBucket: "trackk-2b0c7.appspot.com",
  messagingSenderId: "94017388863",
  appId: "1:94017388863:web:495209d82bd509d6aa138a",
  measurementId: "G-GCF9GKKVHY"
};

initializeApp(firebaseConfig);
const auth = getAuth();

const providerGoogle = new GoogleAuthProvider();
const providerMicrosoft = new OAuthProvider('microsoft.com');

providerGoogle.setCustomParameters({ prompt: 'select_account' });
providerMicrosoft.setCustomParameters({ prompt: 'consent' });

export function signInWithGoogle() {
  return signInWithPopup(auth, providerGoogle)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      return signInWithGoogleAPI(token)
    })
}

export function signInWithMicrosoft() {
  return signInWithPopup(auth, providerMicrosoft)
    .then((result) => {
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      return auth.currentUser.getIdToken().then((idToken) => {
        return signInWithGoogleMicrosoftAPI(idToken, accessToken)
      })
    })
}

export function signOut() {
  return auth.signOut()
}