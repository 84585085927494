<template>
  <div id="user-search" class="mt-4 flex">
    <div class="flex-1">
      <div>
        <v-text-field class="p-0 m-0" v-model="searchTerm" append-icon="mdi-magnify" label="Search" single-line
          hide-details></v-text-field>
      </div>
      <div>
        <v-data-table :headers="headers" :items="users" :items-per-page="itemsPerPage" :server-items-length="totalCount"
          :footer-props="footerOptions" :loading="loading" :disable-sort="true" @update:page="onUserPageUpdate"
          @click:row="onRowClick" item-key="_id" no-data-text="No users found">
          <template v-slot:[`item.fullName`]="{ item }">
            <v-avatar size="36px" color="teal">
              <v-img v-if="item.profileImageUrl" alt="Avatar" :src="item.profileImageUrl" />
              <span v-else class="white--text">{{ item.initials }}</span>
            </v-avatar>
            <span class="ml-2">{{ item.fullName }}</span>
          </template>
          <template v-slot:[`item.squads`]="{ item }">
            <span>{{ getSquadsList(item) }}</span>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- <div class="flex-1 ml-4">
      <div class="mt-2">Selected Members</div>
      <v-data-table class="mt-0.5" :headers="headers" :items="selectedUsers" :footer-props="footerOptions"
        no-data-text="No users selected" item-key="_id" @click:row="onRowClickSelected">
        <template v-slot:[`item.fullName`]="{ item }">
          <v-avatar size="36px" color="teal">
            <v-img v-if="item.profileImageUrl" alt="Avatar" :src="item.profileImageUrl" />
            <span v-else class="white--text">{{ item.initials }}</span>
          </v-avatar>
          <span class="ml-2">{{ item.fullName }}</span>
        </template>
        <template v-slot:[`item.squads`]="{ item }">
          <span>{{ getSquadsList(item) }}</span>
        </template>
      </v-data-table>
    </div> -->
  </div>
</template>
<script>
import { get, handleError } from "../../services/database";

export default {
  name: "UserSearch",
  props: {
    value: Array,
  },
  data() {
    return {
      users: [],
      selectedUsers: this.value || [],
      squadName:
        this.value &&
          this.value[0] &&
          this.value[0].squads &&
          this.value[0].squads[0]
          ? this.value[0].squads[0].name
          : null,
      searchTerm: "",
      itemsPerPage: 5,
      searchTimeout: null,
      loading: false,
      currentPage: 0,
      totalCount: 0,
      footerOptions: {
        "items-per-page-options": [5],
      },
      headers: [
        {
          text: "Name",
          value: "fullName",
        },
        {
          text: "Job Title",
          value: "jobTitle",
        },
        // {
        //   text: "Email",
        //   value: "email",
        // },
        {
          text: "Squad(s)",
          value: "squads",
        },
      ],
    };
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    usersToDisplay() {
      const usersToDisplay = this.users.filter(
        (user) =>
          this.selectedUsers.findIndex(
            (selectedUser) => selectedUser._id === user._id
          ) < 0
      );
      console.log("usersToDisplay", usersToDisplay);
      return usersToDisplay;
    },
  },
  watch: {
    searchTerm(newValue) {
      this.loading = true;
      if (!newValue.length) {
        this.getUsers(0);
      } else {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.getUsers(0), 1000);
      }
    },
  },
  methods: {
    onUserPageUpdate(page) {
      const dbPage = page - 1;

      this.currentPage = dbPage;
      this.getUsers(dbPage);
    },
    getUsers(page = 0) {
      this.loading = true;
      return get("Users", "overview", null, {
        itemsPerPage: this.itemsPerPage,
        page,
        searchTerm: this.searchTerm,
      })
        .then(({ data, totalCount }) => {
          this.users = data;
          this.totalCount = totalCount;
        })
        .catch((error) => {
          handleError(error, this);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSquadsList(user) {
      const squadListLimit = 1;
      if (user.squads.length === 0) {
        return "-";
      } else if (user.squads.length > squadListLimit) {
        return (
          user.squads
            .slice(0, squadListLimit)
            .map((squad) => squad.name)
            .join(", ") + ` + ${user.squads.length - squadListLimit} more`
        );
      } else {
        return user.squads.map((squad) => squad.name).join(", ");
      }
    },
    // onRowClickSelected(user) {
    //   const userInListIndex = this.selectedUsers.findIndex(
    //     (userInList) => userInList._id === user._id
    //   );

    //   if (userInListIndex >= 0) {
    //     this.selectedUsers.splice(userInListIndex, 1);
    //   }

    //   this.$emit("input", this.selectedUsers);
    // },
    onRowClick(user) {
      const userInListIndex = this.selectedUsers.findIndex(
        (userInList) => userInList._id === user._id
      );

      if (userInListIndex == -1) {
        if (
          user.squads.length &&
          (user.squads[0].name !== this.squadName || !this.squadName)
        ) {
          this.$emit("alert", {
            type: "error",
            message: "User already belongs to another squad",
          });
        } else {
          this.selectedUsers.push(user);
          if (user.squads && user.squads[0]) {
            this.squadName = user.squads[0].name;
          }
        }
      }

      console.log('this.selectedUsers', this.selectedUsers)

      this.$emit("input", this.selectedUsers);
    },
  },
};
</script>
<style>
#user-search tbody tr {
  cursor: pointer;
}
</style>