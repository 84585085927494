<template>
  <div id="user-page" class="h-full flex">
    <div class="flex-1 mx-12 flex flex-column">
      <div class="flex-1 flex flex-column justify-center align-center p-6">
        <div class="mb-4">
          <v-avatar size="150px" color="teal" class="text-6xl">
            <v-img
              v-if="user.profileImageUrl"
              alt="Profile picture"
              :src="user.profileImageUrl"
            />
            <span v-else class="white--text">{{ user.initials }}</span>
          </v-avatar>
        </div>

        <ModelEdit
          type="User"
          v-if="user._id && isEditAllowed"
          :options="user"
          v-on:saved="updateUser"
          v-on:deleted="updateUser"
          v-on:closed="updateUser"
          title="Edit user"
          v-on:alert="(alert) => $emit('alert', alert)"
        >
          <template v-slot:activator="{ on }">
            <v-btn outlined v-on="on" class="text-center text-green-mid"
              >Edit</v-btn
            >
          </template>
        </ModelEdit>
      </div>

      <div class="flex-2">
        <div class="mb-4">
          <div>
            <span class="font-bold text-2xl"
              >{{ user.firstName }} {{ user.lastName }}</span
            >
            <span
              v-if="!user.isEmployed"
              class="
                text-sm
                mx-2
                px-2
                py-1
                bg-dark
                text-light
                rounded-lg
                font-bold
              "
              >Inactive</span
            >
            <span
              v-if="user.deleted"
              class="
                text-sm
                mx-2
                px-2
                py-1
                bg-red-600
                text-light
                rounded-lg
                font-bold
              "
              >Deleted</span
            >
          </div>

          <div class="font-weight-light text-gray-600">
            {{ user.jobTitle }}
            <span v-if="user.employmentType === 'contract'">(contractor)</span>
          </div>

          <div class="font-weight-light text-gray-600">
            <div v-if="user.squads && user.squads[0]">
              <v-icon class="mb-1.5">mdi-account-group</v-icon>
              <span class="ml-2">{{ user.squads[0].name }}</span>
            </div>
          </div>

          <div class="font-weight-light text-gray-600">
            <v-icon>mdi-email-outline</v-icon
            ><span class="ml-2">{{ user.email }}</span>
          </div>
        </div>

        <div class="flex mb-4">
          <div class="flex-1">
            <div class="text-sm font-weight-light text-gray-600">
              Work hour limit
            </div>
            <div class="font-medium">{{ user.workHoursPerWeek }}h/week</div>
          </div>

          <!-- <div class="flex-1">
            <div class="text-sm font-weight-light text-gray-600">Rate</div>
            <div class="font-medium">
              {{ user.costCurrency }}{{ user.costPerHour }}/h
            </div>
            <div v-if="user.employmentType === 'permanent'" class="text-sm">
              (based on yearly salary of {{ user.costCurrency
              }}{{ user.cost.toLocaleString() }})
            </div>
            <div
              v-if="
                user.employmentType === 'contract' && user.costType === 'daily'
              "
              class="text-sm"
            >
              (based on daily rate of {{ user.costCurrency
              }}{{ user.cost.toLocaleString() }})
            </div>
          </div> -->

          <!-- <div class="flex-1">
            <div v-if="user.employmentType === 'permanent'">
              <div class="text-sm font-weight-light text-gray-600">Salary</div>
              <div class="font-medium">
                {{ user.costCurrency }}{{ user.cost.toLocaleString() }}
              </div>
            </div>

            <div v-if="user.employmentType === 'contract'">
              <div class="text-sm font-weight-light text-gray-600">Rate</div>
              <div class="font-medium">
                {{ user.costCurrency }}{{ user.cost.toLocaleString() }}/{{
                  user.costType === "hourly" ? "hour" : "day"
                }}
              </div>
            </div>
          </div> -->
        </div>

        <div class="mb-4">
          <div class="text-sm font-weight-light text-gray-600">
            Working hours
          </div>
          <div v-bind:key="index" v-for="(timeShift, index) in user.timeShifts">
            <div class="font-weight-medium">
              <span>
                {{ parseTimeShiftDays(timeShift.days) }}
              </span>
              :
              {{ timeShift.start.hour }}:<span
                v-if="timeShift.start.minute < 10"
                >0</span
              >{{ timeShift.start.minute }}
              <span class="text-xs">{{ timeShift.start.meridiem }}</span>

              - {{ timeShift.end.hour }}:<span v-if="timeShift.end.minute < 10"
                >0</span
              >{{ timeShift.end.minute }}
              <span class="text-xs">{{ timeShift.end.meridiem }}</span>
            </div>
          </div>
        </div>

        <!-- <div v-if="user.skills && user.skills.length">
          <div class="text-sm font-weight-light text-gray-600">Skills</div>
          <div class="flex flex-wrap">
            <div
              v-for="(skill, index) in user.skills"
              :key="index"
              class="text-sm px-2 py-1 mr-2 mb-2 bg-green-100 text-green-700"
            >
              {{ skill.name }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="flex-3 flex flex-column my-4">
      <div class="flex-1 mb-4 mr-4 flex">
        <v-card class="flex-1 h-full p-4">
          <Chart
            :labels="totalCapacityChartLabels"
            :datasets="totalCapacityChartDatasets"
            :options="chartOptions"
          />
        </v-card>
      </div>
      <div class="flex-1 flex">
        <div class="flex-1 mr-4 flex flex-column">
          <div class="flex-3">
            <v-card class="p-4 h-full">
              <div class="mb-2">Squad Capacity Report</div>

              <div v-if="user.squads && user.squads[0]">
                <v-tabs height="36px" color="teal" v-model="userSquadSprintTab">
                  <v-tab key="past">Past</v-tab>
                  <v-tab key="upcoming">Upcoming</v-tab>
                </v-tabs>

                <v-tabs-items v-model="userSquadSprintTab">
                  <v-tab-item key="past">
                    <UserSquadSprintTable :items="userSquadSprintsPast" />
                  </v-tab-item>
                  <v-tab-item key="upcoming">
                    <UserSquadSprintTable :items="userSquadSprintsUpcoming" />
                  </v-tab-item>
                </v-tabs-items>
              </div>
              <div v-else class="font-medium">
                User is not assigned to a squad
              </div>
            </v-card>
          </div>
        </div>
        <div class="flex-1 mr-4">
          <v-card class="p-4 h-full flex flex-column">
            <div class="mb-2">Calendar</div>
            <v-calendar
              v-model="currentDate"
              :events="timeOffEvents"
              event-color="teal"
              class="user-page-calendar"
              ref="calendar"
            >
              <template v-slot:event="{ event }">
                <div class="bg-green-mid text-light leading-none rounded-md">
                  <ModelEdit
                    type="TimeOff"
                    v-on:saved="updateUser"
                    title="Edit time off"
                    :options="parseEventToTimeOff(event)"
                    v-on:alert="(alert) => $emit('alert', alert)"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        text
                        ref="timeOffActivatorButton"
                        v-on="on"
                        class="p-0 text-xl h-full w-full min-w-max text-light"
                        ><span class="leading-none">-</span></v-btn
                      >
                    </template>
                  </ModelEdit>
                </div>
              </template>
            </v-calendar>

            <div class="flex">
              <div class="flex-1">
                <ModelEdit
                  v-if="user && user._id"
                  type="TimeOff"
                  v-on:saved="updateUser"
                  title="Add time off"
                  :options="{ userId: user._id }"
                  v-on:alert="(alert) => $emit('alert', alert)"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      ref="timeOffActivatorButton"
                      v-on="on"
                      class="mt-2 text-center w-full bg-green-mid text-light"
                      >Add time off</v-btn
                    >
                  </template>
                </ModelEdit>
              </div>
              <v-btn class="flex-1 mt-2 ml-4">View all</v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  format,
  // addDays
} from "date-fns";

import { get } from "../../services/database";
import { parseTimeShiftDays } from "../../services/user";
import ModelEdit from "../../components/ModelEdit";
import Chart from "../../components/Chart.vue";
import UserSquadSprintTable from "../../components/authenticated/UserSquadSprintTable";

export default {
  name: "UserPage",
  components: {
    ModelEdit,
    Chart,
    UserSquadSprintTable,
  },
  props: {
    loading: Boolean,
    currentUser: Object,
  },
  data: () => ({
    currentDate: format(new Date(), "yyyy-MM-dd"),
    userSquadSprintTab: "past",
    user: {},
    reports: null,
    userSquadSprintsPast: [],
    userSquadSprintsUpcoming: [],
    chartOptions: {
      maintainAspectRatio: false,
      responsive: true,
      // scales: {
      //   y: {
      //     grace: "10%",
      //   },
      // },
      plugins: {
        legend: {
          onClick: (e) => e.stopPropagation(),
        },
      },
    },
  }),
  computed: {
    isEditAllowed() {
      if (this.user) {
        if (this.currentUser._id === this.user._id) return true;

        return this.currentUser.employments[0].login.permissions.includes(
          "manage_users"
        );
      }

      return false;
    },
    timeOffEvents() {
      if (this.user.timeOff) {
        const events = this.user.timeOff.map((timeOff) => {
          return {
            start: timeOff.startDate,
            end: timeOff.endDate,
          };
        });

        return events;
      } else {
        return [];
      }
    },
    totalCapacityChartLabels() {
      if (this.reports) {
        const labels = this.reports.totalCapacity.map((report) =>
          format(new Date(report.dateMilliseconds), "MMM")
        );

        return labels;
      }
      return [];
    },
    totalCapacityChartDatasets() {
      let dataTotal = [];
      // let dataActual = [];

      if (this.reports) {
        dataTotal = this.reports.totalCapacity.map((report) => report.value);
        // dataActual = this.reports.totalCapacity.map((report) => report.value);
      }

      return [
        {
          label: "Total Capacity",
          data: dataTotal,
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.3,
        },
        // {
        //   label: "Actual Capacity",
        //   data: dataActual,
        //   fill: false,
        //   borderColor: "rgb(72,61,139)",
        //   tension: 0.3,
        // },
      ];
    },
  },
  methods: {
    parseEventToTimeOff(event) {
      if (this.user && this.user.timeOff) {
        const _id = this.user.timeOff.find((timeOff) => {
          return (
            timeOff.startDate === event.start && timeOff.endDate === event.end
          );
        })._id;

        return {
          _id,
          userId: this.user._id,
          type: event.name,
          startDate: event.start,
          endDate: event.end,
        };
      }
    },
    updateUser: function () {
      this.getUser();
    },
    getUser() {
      return Promise.all([
        get("User", "full", this.$route.params.id),
        get("UserReport", "full", this.$route.params.id, {
          type: "monthly",
          count: 6,
        }),
      ])
        .then(([user, userReports]) => {
          this.user = user;
          this.reports = userReports;

          if (!user.squads || !user.squads[0]) return [[], []];

          return Promise.all([
            get("UserSquadSprints", "full", this.$route.params.id, {
              pastSprints: 4,
            }),
            get("UserSquadSprints", "full", this.$route.params.id, {
              upcomingSprints: 4,
            }),
          ]);
        })
        .then(([userSquadSprintsPast, userSquadSprintsUpcoming]) => {
          this.userSquadSprintsPast = userSquadSprintsPast;
          this.userSquadSprintsUpcoming = userSquadSprintsUpcoming;
        });
    },
    parseTimeShiftDays,
  },
  mounted() {
    this.$emit("loading", true);
    this.getUser()
      .catch((error) => {
        if (error.code === "auth/argument-error") {
          this.$emit("alert", { type: "warning", message: "Not logged in" });
          this.$router.push("/login");
        } else {
          // TODO emit error for alert icon
        }
      })
      .finally(() => {
        this.$emit("loading", false);
      });
  },
};
</script>
<style>
.user-page-calendar.v-calendar,
.user-page-calendar.v-calendar .v-calendar-weekly__day,
.user-page-calendar.v-calendar .v-calendar-weekly__head-weekday {
  border-color: rgb(242, 242, 242);
}

/* .user-page-calendar .v-calendar-weekly__day {
  display: flex;
  flex-direction: column;
} */

.user-page-calendar .v-calendar-weekly__day .v-event {
  display: block !important;
  /* flex: 1; */
}

.user-page-calendar .v-calendar-weekly__day .v-event-more {
  display: none !important;
}

#user-page .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
#user-page .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0;
}

#user-page .v-data-footer__pagination {
  margin: 0;
}

#user-page .v-btn--fab.v-size--small {
  height: 30px;
  width: 30px;
}

#user-page tbody tr {
  cursor: pointer;
}
</style>