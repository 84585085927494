<template>
  <div class="mb-2">
    <v-text-field
      v-if="value.type === 'text'"
      v-model="currentValue"
      outlined
      :name="value.autocomplete"
      :label="value.label"
      :placeholder="value.placeholder"
      :required="value.required"
      :rules="rules"
      hide-details="auto"
    />
    <v-textarea
      v-if="value.type === 'textarea'"
      v-model="currentValue"
      outlined
      :no-resize="value.noResize"
      :rows="value.rows"
      :name="value.autocomplete"
      :label="value.label"
      :placeholder="value.placeholder"
      :required="value.required"
      :rules="rules"
      hide-details="auto"
    />
    <v-text-field
      v-if="value.type === 'number'"
      v-model.number="currentValue"
      type="number"
      outlined
      :name="value.autocomplete"
      :label="value.label"
      :placeholder="value.placeholder"
      :required="value.required"
      :rules="rules"
      hide-details="auto"
    />
    <h1
      v-if="value.type === 'title'"
      class="text-xl"
      :class="value.classes ? value.classes : 'mt-8'"
    >
      {{ value[value.type] }}
    </h1>
    <v-radio-group v-if="value.type === 'radio'" v-model="currentValue">
      <v-radio
        v-for="(item, index) in value.items"
        :key="index"
        class="text-green-light"
        :label="item.label"
        :value="item.value"
      />
    </v-radio-group>
    <v-select
      v-if="value.type === 'select'"
      v-model="currentValue"
      :placeholder="value.placeholder"
      :label="value.label"
      :items="value.items"
      item-text="label"
      item-disabled="disabled"
      class="h-14 full-height-input mt-0"
      hide-details="auto"
      outlined
    />

    <v-combobox
      v-if="value.type === 'select-multiple'"
      :placeholder="value.placeholder"
      v-model="currentValue"
      class="text-left"
      :items="value.items"
      :label="value.label"
      :item-text="value.textValue"
      :item-value="value.modelValue"
      hide-details="auto"
      multiple
      outlined
    >
      <!-- :return-object="false" -->
      <template v-slot:selection="{ index }">
        <div
          class="
            px-4
            py-2
            mr-2
            mt-1
            mb-1
            bg-green-light bg-opacity-40
            text-dark
          "
        >
          {{ value.items[index][value.textValue] }}
        </div>
      </template>
    </v-combobox>

    <TimeShiftSelector
      v-if="value.type === 'time-shifts'"
      v-model="currentValue"
      :default-values="value.default"
    />

    <QueryData
      v-if="value.type === 'query'"
      v-model="currentValue"
      :model-type="value.query"
      :fields="value.fields"
      :field-default="value.fieldDefault"
      :placeholder="value.placeholder"
      :results-default="value.resultsDefault"
    />
    <ImageUploader
      v-if="value.type === 'image-upload'"
      v-model="currentValue"
      :label="value.label"
    />

    <DateRangePicker v-if="value.type === 'dateRange'" v-model="currentValue" />
    <DatePicker
      v-if="value.type === 'date-select'"
      :label="value.label"
      :width="value.width"
      v-model="currentValue"
    />

    <div v-if="value.type === 'key-value'" class="mb-10">
      <div v-for="(pair, index) in currentValue" :key="index" class="flex mb-2">
        <v-text-field
          v-model="currentValue[index][value.keyFieldName]"
          outlined
          hide-details="auto"
          placeholder="Key"
          :rules="rules"
        />
        <v-text-field
          v-model.number="currentValue[index].value"
          type="number"
          class="ml-2"
          outlined
          hide-details="auto"
          placeholder="Value"
          :rules="rules"
        />
        <v-btn icon class="m-auto ml-2" @click="currentValue.splice(index, 1)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-btn @click="() => addValueToArray(currentValue, value)"> Add </v-btn>
    </div>

    <UserSearch
      v-if="value.type === 'user-search'"
      v-model="currentValue"
      @alert="(alert) => $emit('alert', alert)"
    />

    <UserList
      v-if="value.type === 'user-list'"
      v-model="currentValue"
      @alert="(alert) => $emit('alert', alert)"
    />

    <TextArray
      v-if="value.type === 'text-array'"
      v-model="currentValue"
      :label="value.label"
      :placeholder="value.placeholder"
    />

    <UserInvites
      v-if="value.type === 'user-invites'"
      v-model="currentValue"
      @alert="(alert) => $emit('alert', alert)"
    />

    <v-divider v-if="value.type === 'divider'" class="my-6" />
  </div>
</template>
<script>
import {
  registerListener,
  triggerListeners,
} from "../../services/schema_field_listener";

import TimeShiftSelector from "./TimeShiftSelector";
import QueryData from "./QueryData";
import DateRangePicker from "./DateRangePicker";
import DatePicker from "./DatePicker";
import ImageUploader from "./ImageUploader";
import UserSearch from "./UserSearch";
import UserList from "./UserList";
import TextArray from "./TextArray";
import UserInvites from "./UserInvites";

export default {
  name: "SchemaField",
  components: {
    TimeShiftSelector,
    QueryData,
    DateRangePicker,
    DatePicker,
    ImageUploader,
    UserSearch,
    UserList,
    TextArray,
    UserInvites,
  },
  props: ["previousValue", "value", "update"],
  data: function () {
    const defaultRules = [];
    if (this.value.required) {
      defaultRules.push((value) => !!value || "Required");
    }

    return {
      currentValue: this.previousValue || this.value.default || null,
      rules: defaultRules,
    };
  },
  watch: {
    currentValue: {
      handler: function (value) {
        this.update(this.value.model, value);
        triggerListeners(this.value.model, value);
      },
      deep: true,
    },
  },
  mounted: function () {
    // set modal to default if no previous value found
    if (!this.previousValue && this.value.default) {
      this.update(this.value.model, this.value.default);
    }

    registerListener(this.value.model, (newValue) => {
      this.currentValue = newValue;
    });
  },
  methods: {
    addValueToArray(array, value) {
      array.push({ [value.keyFieldName]: "", value: "" });
    },
    ObjectArrayUpdateParent(field, value) {
      if (!Array.isArray(this.currentValue)) {
        this.currentValue = [];
      }

      // TODO how do we know which one in array to update?
      this.currentValue[0] = {
        [field]: value,
      };
    },
  },
};
</script>
<style>
.contract-input .v-text-field--outlined .v-input__prepend-outer {
  margin-top: 0;
}

.number-input--hidden-spin input[type="number"] {
  -moz-appearance: textfield;
}

.number-input--hidden-spin input::-webkit-outer-spin-button,
.number-input--hidden-spin input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>