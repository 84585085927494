<template>
  <div id="user-management-page" class="p-4">
    <v-card>
      <div class="p-4">
        <div>
          <span class="text-2xl text-dark font-bold">Manage users</span>
        </div>
        <div class="flex mt-4">
          <v-text-field
            class="p-0 m-0"
            v-model="searchTerm"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer />

          <div class="flex flex-row-reverse">
            <ModelEdit
              type="UserInvites"
              v-on:saved="updateUserList"
              :showDelete="false"
              title="Invite users"
              width="900"
              v-on:alert="(alert) => $emit('alert', alert)"
            >
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" class="bg-green-light">
                  Invite users
                </v-btn>
              </template>
            </ModelEdit>

            <v-btn
              v-if="selectedUsers.length"
              dark
              color="blue"
              class="mr-2"
              @click="endEmploymentForUsers"
            >
              Enable login{{ selectedUsers.length > 1 ? "s" : null }}
            </v-btn>

            <v-btn
              v-if="selectedUsers.length"
              dark
              color="red"
              class="mr-2"
              @click="endEmploymentForUsers"
            >
              Remove login{{ selectedUsers.length > 1 ? "s" : null }}
            </v-btn>
          </div>
        </div>
      </div>
      <v-data-table
        v-model="selectedUsers"
        :headers="headers"
        :items="users"
        :items-per-page="itemsPerPage"
        :server-items-length="totalCount"
        :footer-props="footerOptions"
        :loading="loading"
        :disable-sort="true"
        @update:page="onUserPageUpdate"
        @click:row="onRowClick"
        item-key="_id"
        no-data-text="No users found"
      >
        <template v-slot:[`item.fullName`]="{ item }">
          <v-avatar size="36px" color="teal">
            <v-img
              v-if="item.profileImageUrl"
              alt="Avatar"
              :src="item.profileImageUrl"
            />
            <span v-else class="white--text">{{ item.initials }}</span>
          </v-avatar>
          <span class="ml-2">{{ item.fullName }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { get, handleError } from "../../services/database";

import ModelEdit from "../../components/ModelEdit.vue";

export default {
  name: "UserManagementPage",
  components: {
    ModelEdit,
  },
  data() {
    return {
      loading: false,
      users: [],
      selectedUsers: [],
      itemsPerPage: 30,
      searchTerm: "",
      totalCount: 0,
      footerOptions: {
        "items-per-page-options": [10],
      },
      headers: [
        {
          text: "Name",
          value: "fullName",
        },
        {
          text: "Job Title",
          value: "jobTitle",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Login enabled",
          value: "loginEnabled",
        },
      ],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers(page = 0) {
      this.loading = true;
      // TODO need to implement users for admin endpoint and lock it down to permissions
      // return get("UsersForAdmin", "overview", null, {
      return get("Users", "overview", null, {
        itemsPerPage: this.itemsPerPage,
        page,
        searchTerm: this.searchTerm,
      })
        .then(({ data, totalCount }) => {
          this.users = data;
          this.totalCount = totalCount;
        })
        .catch((error) => {
          handleError(error, this);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateUserList() {},
    onUserPageUpdate() {},
    onRowClick() {},
  },
};
</script>
<style>
#user-management-page tbody tr {
  cursor: pointer;
}
</style>