<script>
import {
  VDialog,
  VCard,
  VForm,
  VCardTitle,
  VDivider,
  VCardText,
  VCardActions,
  VBtn,
  VSpacer,
} from "vuetify/lib";

import {
  get,
  handleError,
  query,
  saveData,
  softDelete,
} from "../services/database";
import SchemaFields from "./authenticated/SchemaFields";

export default {
  components: {
    SchemaFields,
    VDialog,
    VCard,
    VForm,
    VCardTitle,
    VDivider,
    VCardText,
    VCardActions,
    VBtn,
    VSpacer,
  },
  props: {
    rootElement: { type: String, default: "div" },
    rootActivator: { type: Boolean, default: false },
    public: { type: Boolean, default: false },
    type: String || Array,
    schemaDataId: String,
    schemaDataName: String,
    showDelete: { type: Boolean, default: true },
    options: Object,
    title: String,
    width: { type: String || Number, default: "auto" },
    maxWidth: {
      type: String || Number,
      default: function () {
        if (this.width !== "auto") return "none";
        return "600";
      },
    },
    value: { type: Boolean, default: false },
  },
  data: function () {
    return {
      formValid: false,
      schema: [],
      fields: [],
      // model: Object.assign({}, this.options),
      model: {},
      dialog: this.value,
    };
  },
  watch: {
    options: function (newValue) {
      this.model = newValue || {};
    },
    value(newValue) {
      this.dialog = newValue;
      this.reset();
      if (newValue) {
        this.showModal();
        // this.model = Object.assign({}, this.options);
      }
    },
    // dialog(newValue) {
    //   this.$emit("input", newValue);
    // },
  },
  computed: {
    dataChanged() {
      return this.model != this.options;
    },
  },
  mounted() {
    this.model = Object.assign({}, this.options);
  },
  methods: {
    reset() {
      this.formValid = false;
      this.schema = [];
      this.fields = [];
      this.model = Object.assign({}, this.options);
      this.dialog = false;
    },
    showModal() {
      this.dialog = true;
      query(
        this.public,
        this.type + "Schema",
        "schema",
        this.schemaDataId
      ).then((modelSchema) => {
        this.schema = modelSchema.schema;
        this.fields = modelSchema.fields;
      });
    },

    getOnMethods() {
      return {
        click: () => {
          this.showModal();
        },
      };
    },
    closeDialog() {
      this.$emit("closed");
      this.dialog = false;
      // this.model = Object.assign({}, this.options); // reset any changes
      this.reset();
    },
    save() {
      if (this.formValid) {
        if (this.schemaDataId && this.schemaDataName) {
          this.model[this.schemaDataName] = this.schemaDataId;
        }
        saveData(this.public, this.type, this.model, this.fields)
          .then((savedModel) => {
            this.$emit("saved", savedModel);
            this.dialog = false;
          })
          .catch((error) => {
            handleError(error, this);
          })
          .finally(() => {
            this.reset();
          });
      } else {
        console.log("form invalid", this.formValid);
      }
    },
    deleteModel() {
      softDelete(this.type, [this.model._id])
        .then(() => {
          get(this.type, "full", this.model._id).then((deletedModel) => {
            this.$emit("deleted", deletedModel);
            this.dialog = false;
          });
        })
        .catch((error) => {
          // TODO this cannot emit to parent as parent doesnt have a watch
          handleError(error, this);
        });
    },
  },
  render(createElement) {
    return createElement(
      this.rootElement,
      { on: this.rootActivator ? this.getOnMethods() : null },
      [
        this.$scopedSlots.activator &&
          this.$scopedSlots.activator({
            on: !this.rootActivator ? this.getOnMethods() : null,
          }),
        this.dialog && (
          <VDialog
            v-model={this.dialog}
            persistent
            width={this.width}
            max-width={this.maxWidth}
          >
            <VCard class="text-left border-none">
              <VForm
                ref="form"
                v-model={this.formValid}
                lazy-validation
                v-on:submit={(e) => {
                  e.preventDefault();
                  const isValid = this.$refs.form.validate()
                  console.log('isValid', isValid)
                  if (isValid) {
                    this.save();
                  }
                }}
              >
                <VCardTitle class="bg-dark text-light font-bold p-4 fixed-card-header">
                  {this.title}
                  {this.dataChanged ? (
                    <span>
                      <span class="mx-4">-</span>
                      <span class="text-green-light">unsaved changes</span>
                    </span>
                  ) : null}
                </VCardTitle>
                <VDivider />
                <VCardText class="p-4">
                  <SchemaFields
                    schema={this.schema}
                    v-model={this.model}
                    v-on:alert={(alert) => this.$emit("alert", alert)}
                  />
                </VCardText>
                <VCardActions>
                  {this.showDelete && (
                    <VBtn
                      class="w-32"
                      color="red"
                      dark
                      v-on:click={this.deleteModel}
                    >
                      Delete
                    </VBtn>
                  )}
                  <VSpacer></VSpacer>
                  <VBtn
                    class="mr-1 ml-3 w-32"
                    color="text-dark"
                    text
                    v-on:click={this.closeDialog}
                  >
                    Cancel
                  </VBtn>
                  <VBtn class="bg-green-light w-32" dark type="submit">

                    Save
                  </VBtn>
                </VCardActions>
              </VForm>
            </VCard>
          </VDialog>
        ),
      ]
    );
  },
};
</script>