<template>
  <div class="flex space-x-2">
    <ScrollingNumberSelector :start="0" :end="12" v-model="timeSelector.hour" />

    <ScrollingNumberSelector
      :start="0"
      :end="59"
      v-model="timeSelector.minute"
      :padSize="2"
    />
    <div class="flex align-center">
      <v-btn
        outlined
        v-on:click="changeMeridiem"
        class="border-gray-400 button-input-height"
      >
        {{ timeSelector.meridiem }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import ScrollingNumberSelector from "./ScrollingNumberSelector";

export default {
  name: "TimeSelector",
  components: {
    ScrollingNumberSelector,
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return this.default;
      },
    },
  },
  data() {
    return {
      timeSelector: this.value
    }
  },
  watch: {
    timeSelector: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    changeMeridiem() {
      this.timeSelector.meridiem = this.timeSelector.meridiem === "AM" ? "PM" : "AM";
    },
  },
};
</script>
<style>
.button-input-height {
  height: 40px !important;
}
</style>