<template>
  <div class="scrolling-number-selector text-center font-bold">
    <div class="flex flex-column">
      <div class="fade-up" :class="{ hide: previous < start }">
        <v-btn icon v-on:click="subtract"
          ><v-icon>mdi-chevron-up</v-icon></v-btn
        >
      </div>
      <div class="text-gray-400" :class="{ hide: previous < start }">
        {{ padSize ? padNumber(previous, padSize) : previous }}
      </div>

      <v-text-field
        dense
        outlined
        hide-details
        type="tel"
        class="text-center"
        :value="padSize ? padNumber(value, padSize) : value"
        v-on:input="currentValueUpdate"
      />

      <div class="text-gray-400" :class="{ hide: next > end }">
        {{ padSize ? padNumber(next, padSize) : next }}
      </div>
      <div class="fade-down" :class="{ hide: next > end }">
        <v-btn icon v-on:click="add"><v-icon>mdi-chevron-down</v-icon></v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ScrollingNumberSelector",
  props: {
    start: Number,
    value: Number,
    end: Number,
    padSize: Number,
  },
  data: function () {
    return {
      previous: this.value - 1,
      currentValue: this.value,
      next: this.value + 1,
    };
  },
  methods: {
    add() {
      this.previous += 1;
      this.currentValue += 1;
      this.next += 1;

      this.$emit('input', this.currentValue)
    },
    subtract() {
      this.previous -= 1;
      this.currentValue -= 1;
      this.next -= 1;

      this.$emit('input', this.currentValue)
    },
    padNumber(value, size, character) {
      character = character || "0";
      value = value + "";
      return value.length >= size
        ? value
        : new Array(size - value.length + 1).join(character) + value;
    },
    currentValueUpdate(newValue) {
      if (newValue && newValue.length) {
        this.currentValue = parseInt(newValue)

        this.previous = parseInt(newValue) - 1;
        this.next = parseInt(newValue) + 1;
      } else {
        this.currentValue = 0
        this.previous = -1;
        this.next = 1;
      }

    }
  },
};
</script>
<style>
.scrolling-number-selector {
  max-width: 100px;
  display: inline-block;
}

.scrolling-number-selector .v-input input {
  text-align: center;
}

.scrolling-number-selector .v-input__control {
  width: 50px;
}

.fade-up {
  position: relative;
  bottom: -15px;
  background-image: linear-gradient(to top, transparent 0%, white 25%);
  background-color: rgba(0, 0, 0, 0);
}

.fade-down {
  position: relative;
  top: -15px;
  background-image: linear-gradient(to bottom, transparent 0%, white 25%);
  background-color: rgba(0, 0, 0, 0);
}

.hide {
  visibility: hidden;
}
</style>