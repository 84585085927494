<template>
  <div>
    <v-card :loading="loading">
      <v-form
        lazy-validation
        class="p-4"
        v-on:submit.prevent="requestSignInWithEmail"
        ref="form"
      >
        <v-text-field
          v-model="email"
          label="Email address"
          type="email"
          name="email"
          outlined
          hide-details="auto"
          :rules="emailInputRules"
          :disabled="loading"
        ></v-text-field>
        <div class="mt-4">
          <v-btn class="w-full bg-dark text-white" large type="submit" :disabled="loading">
            <slot name="button-text"><span>Sign in with email</span></slot>
          </v-btn>
          <div class="m-auto mt-4 max-w-sm text-center text-sm">

            <slot name="help-text"><span>An email will be sent to the above address with a magic link that
            will sign you in</span></slot>

          </div>
        </div>
      </v-form>
    </v-card>
    <EmailSentDialog v-model="showEmailLinkSentDialog" :email="email" />
  </div>
</template>
<script>
import { requestSignInWithEmailLink } from "../../../services/api";

import EmailSentDialog from "./EmailSentDialog.vue";

export default {
  name: "AuthWithEmailForm",
  components: {
    EmailSentDialog,
  },
  data() {
    return {
      email: null,
      loading: false,
      showEmailLinkSentDialog: false,
      emailInputRules: [],
    };
  },
  methods: {
    requestSignInWithEmail() {
      this.emailInputRules = [
        (value) => !!value || "Required",
        (value) =>
          !value ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "E-mail must be valid",
      ];

      const self = this;

      setTimeout(function () {
        const isValid = self.$refs.form.validate();

        if (isValid && self.email) {
          self.loadingEmail = true;
          requestSignInWithEmailLink(self.email).then(() => {
            window.localStorage.setItem("emailForSignIn", self.email);
            self.showEmailLinkSentDialog = true;
            self.loadingEmail = false;
          });
        }
      });
    },
  },
};
</script>